import { compareArrays, disposeSafe, getEnumerator, clear, structuralHash, jsOptions, equals, defaultOf, createAtom } from "./fable_modules/fable-library-js.4.19.3/Util.js";
import * as dat from "dat.gui";
import * as three from "three";
import { some, value as value_23 } from "./fable_modules/fable-library-js.4.19.3/Option.js";
import { split, printf, toText } from "./fable_modules/fable-library-js.4.19.3/String.js";
import { op_Subtraction, equals as equals_1, op_Addition, fromInt32, compare, fromFloat64, op_Division, toInt64, min, max, toFloat64 } from "./fable_modules/fable-library-js.4.19.3/BigInt.js";
import { min as min_1, max as max_1 } from "./fable_modules/fable-library-js.4.19.3/Double.js";
import { map, iterate } from "./fable_modules/fable-library-js.4.19.3/Seq.js";
import { item as item_1, contains } from "./fable_modules/fable-library-js.4.19.3/Array.js";
import { TrackballControls } from "three/addons/controls/TrackballControls.js";
import { Calculation_ItemPut_$reflection, Calculation_Dim__get_Value } from "./Domain/Model.js";
import { Record } from "./fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, bool_type, string_type, int64_type } from "./fable_modules/fable-library-js.4.19.3/Reflection.js";
import { findIndex, length as length_1, map as map_1, reverse, cons, filter, find, isEmpty, empty, sortByDescending, exists } from "./fable_modules/fable-library-js.4.19.3/List.js";

export let demoMode2 = createAtom(false);

export const gui = new dat.GUI({
    autoPlace: false,
});

export const THREE = three;

export const scene = new THREE.Scene();

export let camera = createAtom(defaultOf());

export let renderer = createAtom(defaultOf());

export let rayCaster = createAtom(new THREE.Raycaster());

export let pointer = createAtom(undefined);

export let element = createAtom(defaultOf());

export let INTERSECTED = createAtom(defaultOf());

export function onPointerMove(e) {
    const event = e;
    const bounds = element().getBoundingClientRect();
    if (equals(e.target, element()) && !equals(element(), defaultOf())) {
        if (pointer() == null) {
            pointer(new THREE.Vector2());
        }
        value_23(pointer()).x = ((((event.clientX - bounds.left) / element().clientWidth) * 2) - 1);
        value_23(pointer()).y = ((-((event.clientY - bounds.top) / element().clientHeight) * 2) + 1);
    }
    else {
        pointer(undefined);
    }
}

document.addEventListener("click", (e) => {
    onPointerMove(e);
});

export function init1(root) {
    root.querySelector("#visual-filter").appendChild(gui.domElement);
    element(root.querySelector("#my-canvas"));
    let rect;
    const width = element().offsetWidth;
    rect = {
        height: element().offsetHeight,
        width: width,
    };
    camera(new THREE.PerspectiveCamera(30, (rect.width / rect.height), 20, 10000));
    const opt = {
        antialias: true,
        canvas: root.querySelector("#my-canvas"),
    };
    renderer(new THREE.WebGLRenderer(opt));
    renderer().setClearColor("white");
    renderer().setSize(rect.width, rect.height);
    renderer().shadowMap.enabled = false;
    renderer().setPixelRatio(window.devicePixelRatio);
}

export const axes = new THREE.AxesHelper(20);

export const lengthArrow = new THREE.ArrowHelper((new THREE.Vector3(1, 0, 0)), (new THREE.Vector3(0, 0, 0)), 1500, "blue", 150);

export const heightArrow = new THREE.ArrowHelper((new THREE.Vector3(0, 1, 0)), (new THREE.Vector3(0, 0, 0)), 500, "green", 150);

export const widthArrow = new THREE.ArrowHelper((new THREE.Vector3(0, 0, -1)), (new THREE.Vector3(0, 0, 0)), 500, "red", 150);

export function createTextSprite(text) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext('2d');
    context.font = toText(printf("Bold %dpx Arial"))(20);
    const textWidth = context.measureText(text).width;
    canvas.width = ~~textWidth;
    canvas.height = 30;
    context.font = toText(printf("Bold %dpx Arial"))(20);
    context.fillStyle = "rgba(0, 0, 0, 1.0)";
    context.fillText(text, 0, 20);
    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;
    const material = new THREE.SpriteMaterial(jsOptions((x) => {
        let copyOfStruct = x;
        copyOfStruct.map = texture;
    }));
    const sprite = new THREE.Sprite(material);
    sprite.scale.set(3, 3, 3);
    return sprite;
}

export const lengthLabel = createTextSprite("Length");

export const widthLabel = createTextSprite("Width");

export const heightLabel = createTextSprite("Height");

export const currentContainer = [];

export let lastLoadingOrderController = createAtom(undefined);

export let lastLController = createAtom(undefined);

export let lastHController = createAtom(undefined);

export let lastDController = createAtom(undefined);

export let lastContainer = createAtom(undefined);

export function updateTextScale() {
    const cameraDistance = camera().position.length();
    const setScale = (sprite) => {
        const scale = 0.04 * cameraDistance;
        sprite.scale.set(scale, scale, scale);
    };
    setScale(lengthLabel);
    setScale(widthLabel);
    setScale(heightLabel);
}

export function renderPlane(container, cw, ch, cl, setCamera) {
    let setCamera_1;
    if (equals(container, lastContainer())) {
        setCamera_1 = false;
    }
    else {
        lastContainer(container);
        setCamera_1 = true;
    }
    const x = toFloat64(max(1n, min(40n, toInt64(op_Division(50000n, toInt64(fromFloat64(cw * cl))))))) / 1.5;
    const vol = (cw * cl) * ch;
    const maxC = max_1(ch, max_1(cw, cl));
    const minC = min_1(ch, min_1(cw, cl));
    const x_1 = (((maxC < 1400) && (minC > 200)) && (vol < 500000000)) ? (x / 1.7) : ((((maxC < 1400) && (minC > 200)) && (vol < 1000000000)) ? (x / 2.5) : ((vol > 100000000) ? (x / 3) : ((vol > 10000000) ? (x / 1) : ((vol > 2500000) ? (x / 0.7) : ((vol > 1500000) ? (x / 0.4) : ((vol > 240000) ? (x / 0.5) : ((vol > 40000) ? (x / 4.5) : ((vol > 5000) ? (x / 5) : ((vol > 900) ? (x / 1.5) : (x / 1))))))))));
    const x_2 = ((ch / cw) > 1.5) ? (x_1 * 0.8) : ((((ch / cw) > 0.85) && ((ch / cw) < 0.9)) ? (x_1 / 6) : x_1);
    const x_3 = (x_2 < 0.1) ? (x_2 * 10) : x_2;
    if (setCamera_1) {
        camera().position.set(500 / x_3, 550 / x_3, -700 / x_3);
    }
    scene.remove(...currentContainer);
    iterate((c) => {
        const mesh = c;
        mesh.geometry.dispose();
        mesh.material.dispose();
    }, currentContainer);
    if (contains(lengthLabel, scene.children, {
        Equals: equals,
        GetHashCode: structuralHash,
    })) {
        scene.remove(lengthLabel);
    }
    if (contains(widthLabel, scene.children, {
        Equals: equals,
        GetHashCode: structuralHash,
    })) {
        scene.remove(widthLabel);
    }
    if (contains(heightLabel, scene.children, {
        Equals: equals,
        GetHashCode: structuralHash,
    })) {
        scene.remove(heightLabel);
    }
    const planeGeometry = new THREE.PlaneGeometry(cl, cw);
    const planeMaterial = new THREE.MeshLambertMaterial(jsOptions((x_7) => {
        let copyOfStruct = x_7;
        copyOfStruct.color = "red";
    }));
    const plane = new THREE.Mesh(planeGeometry, planeMaterial);
    plane.receiveShadow = true;
    plane.rotation.x = (-0.5 * 3.141592653589793);
    plane.position.set(0, 0, 0);
    scene.add(plane);
    void (currentContainer.push(plane));
    scene.add(lengthArrow);
    scene.add(widthArrow);
    scene.add(heightArrow);
    scene.add(lengthLabel);
    scene.add(widthLabel);
    scene.add(heightLabel);
    const planeGeometry_1 = new THREE.PlaneGeometry(cl, ch);
    const planeMaterial_1 = new THREE.MeshLambertMaterial(jsOptions((x_8) => {
        let copyOfStruct_1 = x_8;
        copyOfStruct_1.polygonOffset = true;
        let copyOfStruct_2 = x_8;
        copyOfStruct_2.polygonOffsetFactor = 1;
        let copyOfStruct_3 = x_8;
        copyOfStruct_3.polygonOffsetUnits = 1;
        let copyOfStruct_4 = x_8;
        copyOfStruct_4.color = "red";
    }));
    const plane_1 = new THREE.Mesh(planeGeometry_1, planeMaterial_1);
    const planeEdgeGeometry = new THREE.WireframeGeometry(planeGeometry_1);
    const lineMaterial_1 = new THREE.LineBasicMaterial({
        color: "black",
        linewidth: 1,
    });
    const planeWireFrame = new THREE.LineSegments(planeEdgeGeometry, lineMaterial_1);
    plane_1.add(planeWireFrame);
    plane_1.receiveShadow = true;
    plane_1.rotation.z = (1 * 3.141592653589793);
    plane_1.rotation.y = (1 * 3.141592653589793);
    const origin = [0, ch / 2, cw / 2];
    const arrowOrigin = [-cl / 2, 0, cw / 2];
    plane_1.position.set(...origin);
    lengthLabel.material.map.dispose();
    lengthLabel.material.dispose();
    lengthLabel.geometry.dispose();
    widthLabel.material.map.dispose();
    widthLabel.material.dispose();
    widthLabel.geometry.dispose();
    heightLabel.material.map.dispose();
    heightLabel.material.dispose();
    heightLabel.geometry.dispose();
    lengthArrow.position.set(...arrowOrigin);
    widthArrow.position.set(...arrowOrigin);
    heightArrow.position.set(...arrowOrigin);
    lengthLabel.position.set(min_1((cl / 5) + (cl / 2), (cl / 2) + (cl * 0.5)), 0, cw / 2);
    widthLabel.position.set(-cl / 2, 0, -cw * 1.2);
    heightLabel.position.set(-(cl / 2), ch * 1.5, cw / 2);
    scene.add(plane_1);
    void (currentContainer.push(plane_1));
    const tupledArg = [cw, ch, cl];
    const cw_1 = tupledArg[0];
    const ch_1 = tupledArg[1];
    const cl_1 = tupledArg[2];
    const minArrowSize = min_1(cl_1, min_1(cw_1, ch_1)) * 0.3;
    const actualArrowSize = (0.4 * minArrowSize) + minArrowSize;
    lengthArrow.setLength(cl_1 + minArrowSize, 0.4 * minArrowSize);
    widthArrow.setLength(cw_1 + minArrowSize, 0.4 * minArrowSize);
    heightArrow.setLength(ch_1 + minArrowSize, 0.4 * minArrowSize);
}

export const cubeMaterial = new THREE.MeshLambertMaterial({
    color: "green",
    wireframe: true,
});

export const wireframeMaterial = new THREE.MeshBasicMaterial({
    wireframe: true,
    transparent: true,
    color: "black",
});

export const lineMaterial = new THREE.LineBasicMaterial({
    color: "black",
});

export const cubes = [];

export function renderCube(x, y, z, width, height, length, color, L, W, item) {
    const color_1 = item_1(0, split(color, ["?"], undefined, 0));
    const cubeMaterial_1 = new THREE.MeshLambertMaterial({
        color: color_1,
        wireframe: false,
    });
    const cubeGeometry = new THREE.BoxGeometry(length, height, width);
    const edgeGeomerty = new THREE.EdgesGeometry(cubeGeometry);
    const wireFrame = new THREE.LineSegments(edgeGeomerty, lineMaterial);
    const cube = new THREE.Mesh(cubeGeometry, cubeMaterial_1);
    cube.item = item;
    cube.add(wireFrame);
    cube.castShadow = false;
    cube.position.set(z - ((L - length) / 2), y + (height / 2), ((W - width) / 2) - x);
    scene.add(cube);
    void (cubes.push(cube));
}

export function panCameraUpByPercentage(controls, percentage) {
    console.log(some("camera"), camera().position);
    const offset = (10 * (percentage / 100)) * ((camera().position.z > 20000) ? 0.01 : ((camera().position.z > 2000) ? 0.1 : 1));
    const up = new THREE.Vector3(0, 1, 0);
    const z = new THREE.Vector3(1, 0, 0);
    up.applyQuaternion(camera().quaternion);
    controls.target.add(up.clone().multiplyScalar(120));
    controls.target.add(z.clone().multiplyScalar(140));
    controls.update();
}

export function init(root) {
    init1(root);
    const ambientLight = new THREE.AmbientLight("white", 0.8);
    scene.add(ambientLight);
    const dLight = new THREE.DirectionalLight("white", 1.6);
    dLight.position.set(-400, 400, -900);
    dLight.castShadow = false;
    dLight.shadow.mapSize = (new THREE.Vector2(1024, 1024));
    dLight.shadow.camera.far = 130;
    dLight.shadow.camera.near = 40;
    scene.add(dLight);
    const dLight2 = new THREE.DirectionalLight("white", 5);
    dLight2.position.set(-400, 400, 900);
    scene.add(dLight2);
    dLight2.castShadow = false;
    const dLight3 = new THREE.DirectionalLight("white", 2);
    dLight3.position.set(3000, 0, 0);
    dLight3.castShadow = false;
    dLight3.shadow.mapSize = (new THREE.Vector2(1024, 1024));
    dLight3.shadow.camera.far = 130;
    dLight3.shadow.camera.near = 40;
    scene.add(dLight3);
    camera().position.set(500, 550, -700);
    camera().lookAt(scene.position);
    const clock = new THREE.Clock();
    const resizeRendererToDisplaySize = (renderer_2) => {
        const canvas = renderer_2.domElement;
        const width = canvas.clientWidth;
        const height = canvas.clientHeight;
        const needResize = (canvas.width !== width) ? true : (canvas.height !== height);
        if (needResize) {
            renderer_2.setSize(width, height, false);
        }
        return needResize;
    };
    let ttb;
    const tupledArg_1 = [camera(), renderer()];
    const tupledArg = [tupledArg_1[0], tupledArg_1[1]];
    const trackballControls = new TrackballControls(tupledArg[0], tupledArg[1].domElement);
    trackballControls.rotateSpeed = 1;
    trackballControls.zoomSpeed = 1.2;
    trackballControls.panSpeed = 0.8;
    trackballControls.noZoom = false;
    trackballControls.noPan = false;
    trackballControls.staticMoving = true;
    trackballControls.dynamicDampingFactor = 0.3;
    trackballControls.keys = [65, 83, 68];
    ttb = trackballControls;
    const renderScene = (time) => {
        const time_1 = time * 0.001;
        ttb.update(clock.getDelta());
        if (resizeRendererToDisplaySize(renderer())) {
            const canvas_1 = renderer().domElement;
            camera().aspect = (canvas_1.clientWidth / canvas_1.clientHeight);
            camera().updateProjectionMatrix();
        }
        if (pointer() != null) {
            rayCaster().setFromCamera(value_23(pointer()), camera());
            let cubeObjs;
            const collection = map((c) => c, cubes);
            cubeObjs = Array.from(collection);
            const intersects = rayCaster().intersectObjects(cubeObjs, false);
            if (intersects.length > 0) {
                if (!equals(INTERSECTED(), intersects[0].object)) {
                    if (!equals(INTERSECTED(), defaultOf())) {
                        INTERSECTED().material.emissive.setHex(INTERSECTED().currentHex);
                    }
                    INTERSECTED(intersects[0].object);
                    let details;
                    let bubbles = true;
                    let cancelable = true;
                    let composed = true;
                    let detail = INTERSECTED().item;
                    details = (new (class {
                        set bubbles(value_10) {
                            bubbles = value_10;
                        }
                        get bubbles() {
                            return bubbles;
                        }
                        set cancelable(value_11) {
                            cancelable = value_11;
                        }
                        get cancelable() {
                            return cancelable;
                        }
                        set composed(value_12) {
                            bubbles = value_12;
                        }
                        get composed() {
                            return composed;
                        }
                        set detail(value_13) {
                            detail = value_13;
                        }
                        get detail() {
                            return detail;
                        }
                    }
                    )());
                    const customEvent = new CustomEvent("canvasItemSelected", details);
                    element().dispatchEvent(customEvent);
                    INTERSECTED().currentHex = (INTERSECTED().material.emissive.getHex());
                    INTERSECTED().material.emissive.setHex(16711680);
                }
            }
            else {
                if (!equals(INTERSECTED(), defaultOf())) {
                    INTERSECTED().material.emissive.setHex(INTERSECTED().currentHex);
                }
                INTERSECTED(defaultOf());
                pointer(undefined);
            }
        }
        updateTextScale();
        renderer().render(scene, camera());
        setTimeout(() => {
            window.requestAnimationFrame(renderScene);
        }, 33);
    };
    resizeRendererToDisplaySize(renderer());
    renderScene(0);
}

export function renderResultInner(container, items, demoMode, setCamera) {
    demoMode2(demoMode);
    let multiplier;
    const m = max(max(Calculation_Dim__get_Value(container.LWH.Width), Calculation_Dim__get_Value(container.LWH.Height)), Calculation_Dim__get_Value(container.LWH.Length));
    multiplier = ((compare(m, toInt64(fromInt32(200000))) > 0) ? 0.001 : ((compare(m, toInt64(fromInt32(20000))) > 0) ? 0.01 : ((compare(m, toInt64(fromInt32(2000))) > 0) ? 0.1 : 1)));
    const floatish = (x) => (toFloat64(x) * multiplier);
    const matchValue = toFloat64(Calculation_Dim__get_Value(container.LWH.Width)) * multiplier;
    const matchValue_1 = toFloat64(Calculation_Dim__get_Value(container.LWH.Length)) * multiplier;
    renderPlane(container, matchValue, toFloat64(Calculation_Dim__get_Value(container.LWH.Height)) * multiplier, matchValue_1, setCamera);
    let cubeObjs;
    const collection = map((c) => c, cubes);
    cubeObjs = Array.from(collection);
    scene.remove(...cubeObjs);
    iterate((c_1) => {
        c_1.material.dispose();
        c_1.geometry.dispose();
        iterate((w) => {
            w.geometry.dispose();
            w.material.dispose();
        }, c_1.children);
    }, cubes);
    clear(cubeObjs);
    clear(cubes);
    const enumerator = getEnumerator(items);
    try {
        while (enumerator["System.Collections.IEnumerator.MoveNext"]()) {
            const item = enumerator["System.Collections.Generic.IEnumerator`1.get_Current"]();
            renderCube(floatish(item.Coordinates.X), floatish(item.Coordinates.Y), floatish(item.Coordinates.Z), floatish(Calculation_Dim__get_Value(item.Item.LWH.Width)), floatish(Calculation_Dim__get_Value(item.Item.LWH.Height)), floatish(Calculation_Dim__get_Value(item.Item.LWH.Length)), item.Item.Tag, floatish(Calculation_Dim__get_Value(container.LWH.Length)), floatish(Calculation_Dim__get_Value(container.LWH.Width)), item);
        }
    }
    finally {
        disposeSafe(enumerator);
    }
}

export class Box extends Record {
    constructor(X, Y, Z, Length, Width, Height, Tag, AllowFloating, ItemPut) {
        super();
        this.X = X;
        this.Y = Y;
        this.Z = Z;
        this.Length = Length;
        this.Width = Width;
        this.Height = Height;
        this.Tag = Tag;
        this.AllowFloating = AllowFloating;
        this.ItemPut = ItemPut;
    }
}

export function Box_$reflection() {
    return record_type("CanvasRenderer.Box", [], Box, () => [["X", int64_type], ["Y", int64_type], ["Z", int64_type], ["Length", int64_type], ["Width", int64_type], ["Height", int64_type], ["Tag", string_type], ["AllowFloating", bool_type], ["ItemPut", Calculation_ItemPut_$reflection()]]);
}

export function convertToBox(itemPut) {
    const patternInput = itemPut.Item.LWH;
    return new Box(itemPut.Coordinates.X, itemPut.Coordinates.Y, itemPut.Coordinates.Z, Calculation_Dim__get_Value(patternInput.Length), Calculation_Dim__get_Value(patternInput.Width), Calculation_Dim__get_Value(patternInput.Height), itemPut.Item.Tag, false, itemPut);
}

export function isOnTop(box, other) {
    if ((((compare(other.X, toInt64(op_Addition(box.X, box.Width))) < 0) && (compare(toInt64(op_Addition(other.X, other.Width)), box.X) > 0)) && (compare(other.Z, toInt64(op_Addition(box.Z, box.Length))) < 0)) && (compare(toInt64(op_Addition(other.Z, other.Length)), box.Z) > 0)) {
        return equals_1(other.Y, toInt64(op_Addition(box.Y, box.Height)));
    }
    else {
        return false;
    }
}

export function canRemove(box, placedBoxes) {
    return !exists((other) => isOnTop(box, other), placedBoxes);
}

export function generateUnloadingOrder(boxes) {
    let sortedBoxes = sortByDescending((box) => [box.Z, box.Y, box.X], boxes, {
        Compare: compareArrays,
    });
    let removedBoxes = empty();
    while (!isEmpty(sortedBoxes)) {
        const first = find((box_1) => canRemove(box_1, sortedBoxes), sortedBoxes);
        const rest = filter((box_2) => !equals(box_2, first), sortedBoxes);
        sortedBoxes = rest;
        removedBoxes = cons(first, removedBoxes);
    }
    return reverse(removedBoxes);
}

export let lastItems = createAtom(empty());

export let lastContainer2 = createAtom(defaultOf());

export let callback = createAtom(defaultOf());

export function renderResult(container, items, demoMode) {
    if (lastLController() != null) {
        const c = lastLController();
        gui.remove(c);
    }
    const h = {
        "Horizontal filter": 0,
    };
    const v = {
        "Vertical filter": 0,
    };
    const d = {
        "Depth filter": 0,
    };
    const lo = {
        "Loading plan": 0,
    };
    const sortedItems = map_1((x) => x.ItemPut, reverse(generateUnloadingOrder(map_1(convertToBox, items))));
    const length = length_1(items) | 0;
    callback((list_4) => filter((i) => {
        if (((findIndex((x_1) => equals(x_1, i), sortedItems) <= (length - lo["Loading plan"])) && (toFloat64(toInt64(op_Subtraction(Calculation_Dim__get_Value(container.LWH.Length), i.Coordinates.Z))) >= h["Horizontal filter"])) && (toFloat64(toInt64(op_Subtraction(Calculation_Dim__get_Value(container.LWH.Height), i.Coordinates.Y))) >= v["Vertical filter"])) {
            return toFloat64(toInt64(op_Subtraction(Calculation_Dim__get_Value(container.LWH.Width), i.Coordinates.X))) >= d["Depth filter"];
        }
        else {
            return false;
        }
    }, list_4));
    if (lastLoadingOrderController() != null) {
        const c_1 = lastLoadingOrderController();
        gui.remove(c_1);
    }
    lastLoadingOrderController(gui.add(lo, "Loading plan", 0, length_1(items)).onChange((_arg) => {
        renderResultInner(container, callback()(items), demoMode2(), false);
    }));
    lastLController(gui.add(h, "Horizontal filter", 0, toFloat64(Calculation_Dim__get_Value(container.LWH.Length))).onChange((v_1) => {
        renderResultInner(container, callback()(items), demoMode2(), false);
    }));
    if (lastHController() != null) {
        const c_2 = lastHController();
        gui.remove(c_2);
    }
    lastHController(gui.add(v, "Vertical filter", 0, toFloat64(Calculation_Dim__get_Value(container.LWH.Height))).onChange((_arg_1) => {
        renderResultInner(container, callback()(items), demoMode2(), false);
    }));
    if (lastDController() != null) {
        const c_3 = lastDController();
        gui.remove(c_3);
    }
    lastDController(gui.add(d, "Depth filter", 0, toFloat64(Calculation_Dim__get_Value(container.LWH.Width))).onChange((_arg_2) => {
        renderResultInner(container, callback()(items), demoMode2(), false);
    }));
    gui.__closeButton.hidden = true;
    gui.updateDisplay();
    lastItems(items);
    lastContainer2(container);
    renderResultInner(container, items, demoMode, true);
}

export let animRunning = createAtom(false);

export function runAnimationOuter() {
    if (animRunning() === true) {
        animRunning(false);
    }
    else {
        animRunning(true);
        let initialValue = length_1(lastItems());
        const step = max_1(1, ~~(length_1(lastItems()) / 100)) | 0;
        const runAnimation = () => {
            lastLoadingOrderController().setValue(initialValue);
            initialValue = ((initialValue - step) | 0);
            renderResultInner(lastContainer2(), callback()(lastItems()), demoMode2(), false);
            if ((initialValue >= 0) && animRunning()) {
                setTimeout(runAnimation, 1000);
            }
            else {
                initialValue = 0;
                lastLoadingOrderController().setValue(initialValue);
                renderResultInner(lastContainer2(), callback()(lastItems()), demoMode2(), false);
                animRunning(false);
            }
        };
        runAnimation();
    }
}

