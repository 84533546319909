import { Lit_get_nothing, Lit_mapiUnique, LitHelpers_html } from "../fable_modules/Fable.Lit.1.4.2/Lit.fs.js";
import { split, printf, toText, substring, fmt } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { parse } from "../fable_modules/fable-library-js.4.19.3/Int32.js";
import { min, max as max_1 } from "../fable_modules/fable-library-js.4.19.3/Double.js";
import { stringHash, comparePrimitives, numberHash, int32ToString, defaultOf } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { empty, FSharpMap__TryFind } from "../fable_modules/fable-library-js.4.19.3/Map.js";
import { round, fromParts, op_Addition, op_Multiply, op_Division, parse as parse_1, compare } from "../fable_modules/fable-library-js.4.19.3/Decimal.js";
import Decimal from "../fable_modules/fable-library-js.4.19.3/Decimal.js";
import { item as item_5 } from "../fable_modules/fable-library-js.4.19.3/Array.js";
import { maxBy, length, find, sum, item as item_6, map } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { Calculation_Weight__get_Value, Calculation_Dim__get_Value, Calculation_Multiplier, Calculation_Multiplier__get_Value, Calculation_Quantity__get_Value } from "../Domain/Model.js";
import { compare as compare_1, op_Addition as op_Addition_1, toInt64, toDecimal } from "../fable_modules/fable-library-js.4.19.3/BigInt.js";
import { value as value_9, defaultArg } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { List_groupBy } from "../fable_modules/fable-library-js.4.19.3/Seq2.js";
import { copyTextToClipboard } from "../ViewFunctions.js";
import { Msg } from "../MVU/BinDrakeInputForm.js";

export const helpIcon = LitHelpers_html(fmt`
        <svg class="help" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            width="100" height="100"
            viewBox="0 0 24 24">
            <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 12 6 C 9.79 6 8 7.79 8 10 L 10 10 C 10 8.9 10.9 8 12 8 C 13.1 8 14 8.9 14 10 C 14 12 11 12.367 11 15 L 13 15 C 13 13.349 16 12.5 16 10 C 16 7.79 14.21 6 12 6 z M 11 16 L 11 18 L 13 18 L 13 16 L 11 16 z"></path>
        </svg>
    `);

export function darkenColor(amount, hexColor) {
    const hex = (hexColor.indexOf("#") === 0) ? substring(hexColor, 1) : hexColor;
    const hexToInt = (h) => parse(h, 515, false, 32);
    const r = hexToInt(substring(hex, 0, 2)) | 0;
    const g = hexToInt(substring(hex, 2, 2)) | 0;
    const b = hexToInt(substring(hex, 4, 2)) | 0;
    const darken = (value) => max_1(0, min(255, ~~(value * (1 - amount))));
    const rDarkened = darken(r) | 0;
    const gDarkened = darken(g) | 0;
    const bDarkened = darken(b) | 0;
    return toText(printf("#%02x%02x%02x"))(rDarkened)(gDarkened)(bDarkened);
}

export const distinct20ColorsinRGB = ["#42d4f4", "#bfef45", "#ffffff", "#3cb44b", "#f032e6", "#a9a9a9", "#aaffc3", "#fabed4", "#fffac8", "#9A6324", "#7646fb", "#4363d8", "#d400FF", "#f7b6d2", "#800000", "#808000"];

export function dimCell(formData_1, prefix, minValue, maxValue, defaultValue, name, index) {
    const capitalizeFirstLetter = (s) => {
        let matchResult;
        if (s === defaultOf()) {
            matchResult = 0;
        }
        else if (s === "") {
            matchResult = 0;
        }
        else {
            matchResult = 1;
        }
        switch (matchResult) {
            case 0:
                return s;
            default: {
                const firstChar = s[0];
                const rest = s.slice(1, s.length);
                const capitalizedFirstChar = firstChar.toLocaleUpperCase();
                return toText(printf("%c%s"))(capitalizedFirstChar)(rest);
            }
        }
    };
    const formName = (index === "") ? toText(printf("%s-%s"))(prefix)(name) : toText(printf("%s-%s-%s"))(prefix)(index)(name);
    let defaultValue_1;
    const matchValue = FSharpMap__TryFind(formData_1, formName);
    defaultValue_1 = ((matchValue != null) ? matchValue : ((defaultValue != null) ? int32ToString(defaultValue) : ""));
    const lowerName = name.toLowerCase();
    const step = ((lowerName.indexOf("quantity") >= 0) ? true : (lowerName.indexOf("priority") >= 0)) ? "1" : "0.01";
    if (formName === "container-height") {
        return LitHelpers_html(fmt`
            <td><input name='${formName}' aria-label="${name}"
                placeholder='${capitalizeFirstLetter(name)}' inputmode="decimal" 
                    @change=${(e) => {
            let copyOfStruct, copyOfStruct_1, copyOfStruct_2, copyOfStruct_3;
            const element = (((copyOfStruct = e, copyOfStruct.target)).getRootNode()).querySelector("[name=item-0-length]");
            if ((element == null) ? true : (element.value === "")) {
                const containerL = ((((copyOfStruct_1 = e, copyOfStruct_1.target)).getRootNode()).querySelector("[name=container-length]")).value;
                if (compare(parse_1(((copyOfStruct_2 = e, copyOfStruct_2.target)).value), parse_1(containerL)) > 0) {
                    ((((copyOfStruct_3 = e, copyOfStruct_3.target)).getRootNode()).querySelector("[name=calc-mode]")).value = "MinimizeHeight";
                }
            }
        }}
                    step='${step}' required type=number min=${minValue} .value=${defaultValue_1} max=${maxValue}></td>
        `);
    }
    else {
        return LitHelpers_html(fmt`
            <td><input name='${formName}' aria-label="${name}"
                placeholder='${capitalizeFirstLetter(name)}' inputmode="decimal" step='${step}' required type=number min=${minValue} .value=${defaultValue_1} max=${maxValue}></td>
        `);
    }
}

export function handleItemsUnput(items) {
    return LitHelpers_html(fmt` <ul>${Lit_mapiUnique((tuple) => tuple[0], (i, tupledArg) => {
        let name;
        const tag = tupledArg[0];
        return LitHelpers_html(fmt`
                <li>
                    <span data-color=${item_5(0, split(tag, ["?"], undefined, 0))} class="tag">&nbsp;&nbsp;&nbsp;&nbsp;</span> &nbsp; ${(name = item_5(1, split(tag, ["?"], undefined, 0)), (name.indexOf("~") >= 0) ? item_5(0, split(name, ["~"], undefined, 0)) : name)}
                    <span class="count">${toText(printf(": x%i"))(tupledArg[1])}</span>
                </li>
            `);
    }, map((item) => [item.Tag, Calculation_Quantity__get_Value(item.Quantity)], items))}</ul>`);
}

export function cargoStats(model, itemUnitRef, containerUnitRef, itemWUnitRef, containerWUnitRef) {
    const matchValue = model.Result;
    let matchResult, bindrakeRes, m;
    if (matchValue == null) {
        matchResult = 0;
    }
    else {
        const copyOfStruct = matchValue[0];
        if (copyOfStruct.tag === 0) {
            if (model.PendingOrder != null) {
                matchResult = 0;
            }
            else {
                matchResult = 1;
                bindrakeRes = copyOfStruct.fields[0];
                m = matchValue[1].fields[0];
            }
        }
        else {
            matchResult = 0;
        }
    }
    switch (matchResult) {
        case 0:
            return Lit_get_nothing();
        default: {
            const dm = toDecimal(m);
            const weightMultiplier_1 = toDecimal(Calculation_Multiplier__get_Value(defaultArg(bindrakeRes.BinDrakeInput.WeightMultiplier, new Calculation_Multiplier(1n))));
            const lwhToVolume = (lwh) => op_Division(op_Multiply(op_Division(op_Multiply(op_Division(toDecimal(Calculation_Dim__get_Value(lwh.Length)), dm), toDecimal(Calculation_Dim__get_Value(lwh.Width))), dm), toDecimal(Calculation_Dim__get_Value(lwh.Height))), dm);
            const items = item_6(model.CurrentContainerIndex, List_groupBy((x) => x.ContainerIndex, item_6(0, bindrakeRes.OutputRows).ItemsPut, {
                Equals: (x_1, y) => (x_1 === y),
                GetHashCode: numberHash,
            }))[1];
            const totalItemVolume = sum(map(lwhToVolume, map((x_2) => x_2.Item.LWH, items)), {
                GetZero: () => (new Decimal("0")),
                Add: op_Addition,
            });
            const totalItemWeight_1 = op_Division(sum(map((x_6) => (new Decimal(Calculation_Weight__get_Value(find((x_4) => (x_4.Tag === x_6.Item.Tag), bindrakeRes.BinDrakeInput.Items).Weight))), items), {
                GetZero: () => (new Decimal("0")),
                Add: op_Addition,
            }), weightMultiplier_1);
            const container = item_6(0, bindrakeRes.OutputRows).Container;
            const totalContainerVolume = lwhToVolume(container.LWH);
            const totalContainerWeight_1 = op_Division(new Decimal(Calculation_Weight__get_Value(container.MaxWeight)), weightMultiplier_1);
            const formData_1 = bindrakeRes.BinDrakeInput.FormData;
            const itemUnitValue = value_9(FSharpMap__TryFind(formData_1, "xitem-unit"));
            let itemUnit;
            const ddl = value_9(itemUnitRef.contents);
            const children = ddl.children;
            const loop = (i_mut) => {
                loop:
                while (true) {
                    const i = i_mut;
                    const item_1 = children[i];
                    if (item_1.value === itemUnitValue) {
                        return item_5(1, split(item_1.textContent, [":"], undefined, 0)).trim();
                    }
                    else {
                        i_mut = (i + 1);
                        continue loop;
                    }
                    break;
                }
            };
            itemUnit = loop(0);
            const containerUnitValue = value_9(FSharpMap__TryFind(formData_1, "xcontainer-unit"));
            let containerUnit;
            const ddl_1 = value_9(containerUnitRef.contents);
            const children_1 = ddl_1.children;
            const loop_1 = (i_1_mut) => {
                loop_1:
                while (true) {
                    const i_1 = i_1_mut;
                    const item_2 = children_1[i_1];
                    if (item_2.value === containerUnitValue) {
                        return item_5(1, split(item_2.textContent, [":"], undefined, 0)).trim();
                    }
                    else {
                        i_1_mut = (i_1 + 1);
                        continue loop_1;
                    }
                    break;
                }
            };
            containerUnit = loop_1(0);
            const containerUnitTextAsUnit = (containerUnitValue === "1") ? "mm" : ((containerUnitValue === "10") ? "cm" : ((containerUnitValue === "1000") ? "m" : containerUnitValue));
            const itemWUnitValue = value_9(FSharpMap__TryFind(formData_1, "witem-unit"));
            let itemWUnit;
            const ddl_2 = value_9(itemWUnitRef.contents);
            const children_2 = ddl_2.children;
            const loop_2 = (i_2_mut) => {
                loop_2:
                while (true) {
                    const i_2 = i_2_mut;
                    const item_3 = children_2[i_2];
                    if (item_3.value === itemWUnitValue) {
                        return item_3.textContent;
                    }
                    else {
                        i_2_mut = (i_2 + 1);
                        continue loop_2;
                    }
                    break;
                }
            };
            itemWUnit = loop_2(0);
            let containerWUnit;
            const ddl_3 = value_9(containerWUnitRef.contents);
            const children_3 = ddl_3.children;
            const loop_3 = (i_3_mut) => {
                loop_3:
                while (true) {
                    const i_3 = i_3_mut;
                    const item_4 = children_3[i_3];
                    if (item_4.value === itemWUnitValue) {
                        return item_4.textContent;
                    }
                    else {
                        i_3_mut = (i_3 + 1);
                        continue loop_3;
                    }
                    break;
                }
            };
            containerWUnit = loop_3(0);
            const totalContainerWeight_2 = (containerWUnit === "gr") ? totalContainerWeight_1 : ((containerWUnit === "oz") ? totalContainerWeight_1 : ((containerWUnit === "kg") ? ((itemWUnit === "gr") ? op_Division(totalContainerWeight_1, fromParts(1000, 0, 0, false, 0)) : totalContainerWeight_1) : ((containerWUnit === "metric tonnes") ? ((itemWUnit === "gr") ? op_Division(totalContainerWeight_1, fromParts(1000000, 0, 0, false, 0)) : ((itemWUnit === "kg") ? op_Division(totalContainerWeight_1, fromParts(1000, 0, 0, false, 0)) : totalContainerWeight_1)) : ((containerWUnit === "lb") ? ((itemWUnit === "oz") ? op_Division(totalContainerWeight_1, fromParts(16, 0, 0, false, 0)) : totalContainerWeight_1) : totalContainerWeight_1))));
            const totalItemWeight_2 = (itemWUnit === "gr") ? totalItemWeight_1 : ((itemWUnit === "oz") ? totalItemWeight_1 : ((itemWUnit === "kg") ? ((containerWUnit === "gr") ? op_Division(totalItemWeight_1, fromParts(1000, 0, 0, false, 0)) : totalItemWeight_1) : ((itemWUnit === "metric tonnes") ? ((containerWUnit === "gr") ? op_Division(totalItemWeight_1, fromParts(1000000, 0, 0, false, 0)) : ((containerWUnit === "kg") ? op_Division(totalItemWeight_1, fromParts(1000, 0, 0, false, 0)) : totalItemWeight_1)) : ((itemWUnit === "lb") ? ((containerWUnit === "oz") ? op_Division(totalItemWeight_1, fromParts(16, 0, 0, false, 0)) : totalItemWeight_1) : totalItemWeight_1))));
            const patternInput = (containerUnit === "mm") ? [totalContainerVolume, "mm"] : ((containerUnit === "in") ? [totalContainerVolume, "in"] : ((containerUnit === "m") ? ((itemUnit === "cm") ? [op_Division(totalContainerVolume, fromParts(1000000, 0, 0, false, 0)), "cm"] : ((itemUnit === "mm") ? [op_Division(totalContainerVolume, fromParts(1000000000, 0, 0, false, 0)), "mm"] : [totalContainerVolume, containerUnitTextAsUnit])) : ((containerUnit === "cm") ? ((itemUnit === "mm") ? [op_Division(totalContainerVolume, fromParts(1000, 0, 0, false, 0)), "mm"] : [totalContainerVolume, containerUnitTextAsUnit]) : ((containerUnit === "ft") ? ((itemUnit === "in") ? [op_Division(totalContainerVolume, fromParts(1728, 0, 0, false, 0)), "in"] : [totalContainerVolume, containerUnitTextAsUnit]) : [totalContainerVolume, containerUnitTextAsUnit]))));
            const totalContainerVolume_1 = patternInput[0];
            const itemUnit_1 = (itemUnit === "m") ? containerUnit : ((itemUnit === "ft") ? containerUnit : ((itemUnit === "cm") ? ((containerUnit === "mm") ? "mm" : itemUnit) : itemUnit));
            const patternInput_1 = (compare(totalItemVolume, fromParts(10000, 0, 0, false, 0)) >= 0) ? ((itemUnit_1 === "mm") ? [op_Division(totalItemVolume, fromParts(1000000000, 0, 0, false, 0)), "m"] : ((itemUnit_1 === "cm") ? [op_Division(totalItemVolume, fromParts(1000000, 0, 0, false, 0)), "m"] : [totalItemVolume, itemUnit_1])) : [totalItemVolume, itemUnit_1];
            const totalItemVolume_1 = patternInput_1[0];
            const patternInput_2 = (compare(totalContainerVolume_1, fromParts(10000, 0, 0, false, 0)) >= 0) ? ((containerUnit === "mm") ? [op_Division(totalContainerVolume_1, fromParts(1000000000, 0, 0, false, 0)), "m", op_Multiply(fromParts(1000, 0, 0, false, 0), fromParts(100, 0, 0, false, 0))] : ((containerUnit === "cm") ? [op_Division(totalContainerVolume_1, fromParts(1000000, 0, 0, false, 0)), "m", op_Multiply(fromParts(100, 0, 0, false, 0), fromParts(100, 0, 0, false, 0))] : [totalContainerVolume_1, containerUnit, fromParts(1, 0, 0, false, 0)])) : [totalContainerVolume_1, containerUnit, fromParts(1, 0, 0, false, 0)];
            const totalContainerVolume_2 = patternInput_2[0];
            const totalContainerVolume_3 = (compare(totalContainerVolume_2, fromParts(1, 0, 0, false, 0)) > 0) ? round(totalContainerVolume_2, 3) : totalContainerVolume_2;
            const totalItemVolume_2 = (compare(totalItemVolume_1, fromParts(1, 0, 0, false, 0)) > 0) ? round(totalItemVolume_1, 3) : totalItemVolume_1;
            return LitHelpers_html(fmt`
                <p>Container interior dims: 
                    <span class=number>${toDecimal(Calculation_Dim__get_Value(container.LWH.Length)) / dm} </span> x 
                    <span class=number>${toDecimal(Calculation_Dim__get_Value(container.LWH.Width)) / dm} </span> x
                    <span class=number>${toDecimal(Calculation_Dim__get_Value(container.LWH.Height)) / dm} ${patternInput[1]} </span>
                </p> 
                <p>Loaded Volume: <span class=number>${totalItemVolume_2} ${patternInput_1[1]}<sup>3</sup></span> / <span class=number> ${totalContainerVolume_3} ${patternInput_2[1]}<sup>3</sup></span></p> 
                <p>Loaded Weight:&nbsp; <span class=number>${totalItemWeight_2} ${itemWUnit}</span> / <span class=number> ${totalContainerWeight_2} ${containerWUnit}</span></p> 
            `);
        }
    }
}

export function shareButton(model) {
    const matchValue = model.Result;
    let matchResult;
    if (matchValue != null) {
        if (matchValue[0].tag === 0) {
            matchResult = 0;
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return LitHelpers_html(fmt`
        <span>
            <button type="button" 
                @click=${(_arg) => {
                copyTextToClipboard(window.location.href);
            }}>
                Copy Result URL
            </button>  
            <tool-tip inert role="tooltip" tip-position="top">
                When you click this button, the URL of the current result will be copied to your clipboard. 
                You can bookmark this URL to return to this result later or share it with others. 
                To view the results, others will have to register and login which is free
            </tool-tip> 
        </span>
        `);
        default:
            return Lit_get_nothing();
    }
}

export function isCalculating(model) {
    if (model.CalculationStatus.tag === 1) {
        return true;
    }
    else {
        return false;
    }
}

export function formData(model) {
    const matchValue = model.CurrentForm;
    if (matchValue != null) {
        return matchValue;
    }
    else {
        return empty({
            Compare: comparePrimitives,
        });
    }
}

export function handleResult(model, itemUnitRef, dispatch) {
    if (isCalculating(model)) {
        return LitHelpers_html(fmt`<p>Calculating results...</p>`);
    }
    else if (model.Error != null) {
        window.showError(`There is a problem with input: ${value_9(model.Error)}`);
        return Lit_get_nothing();
    }
    else if (model.Result == null) {
        return LitHelpers_html(fmt`<p>No results calculated yet</p>`);
    }
    else {
        const matchValue = value_9(model.Result);
        const copyOfStruct = matchValue[0];
        if (copyOfStruct.tag === 1) {
            return LitHelpers_html(fmt`<p>An error occurred ${copyOfStruct.fields[0]}</p>`);
        }
        else {
            const outputRow = item_6(0, copyOfStruct.fields[0].OutputRows);
            const notPut = outputRow.ItemsNotPut;
            const multiplier_1 = new Decimal(matchValue[1].fields[0]);
            const itemsGroup = List_groupBy((x) => x.ContainerIndex, outputRow.ItemsPut, {
                Equals: (x_1, y) => (x_1 === y),
                GetHashCode: numberHash,
            });
            const pageCount = length(itemsGroup) | 0;
            const items = item_6(model.CurrentContainerIndex, itemsGroup)[1];
            let maxW;
            const maxItem = maxBy((x_2) => toInt64(op_Addition_1(x_2.Coordinates.X, Calculation_Dim__get_Value(x_2.Item.LWH.Width))), items, {
                Compare: compare_1,
            });
            maxW = toDecimal(toInt64(op_Addition_1(maxItem.Coordinates.X, Calculation_Dim__get_Value(maxItem.Item.LWH.Width))));
            let maxH;
            const maxItem_1 = maxBy((x_4) => toInt64(op_Addition_1(x_4.Coordinates.Y, Calculation_Dim__get_Value(x_4.Item.LWH.Height))), items, {
                Compare: compare_1,
            });
            maxH = toDecimal(toInt64(op_Addition_1(maxItem_1.Coordinates.Y, Calculation_Dim__get_Value(maxItem_1.Item.LWH.Height))));
            let maxL;
            const maxItem_2 = maxBy((x_6) => toInt64(op_Addition_1(x_6.Coordinates.Z, Calculation_Dim__get_Value(x_6.Item.LWH.Length))), items, {
                Compare: compare_1,
            });
            maxL = toDecimal(toInt64(op_Addition_1(maxItem_2.Coordinates.Z, Calculation_Dim__get_Value(maxItem_2.Item.LWH.Length))));
            const totalItems = sum(map((x_8) => Calculation_Quantity__get_Value(x_8.Item.Quantity), items), {
                GetZero: () => 0,
                Add: (x_9, y_4) => (x_9 + y_4),
            }) | 0;
            const formData_1 = copyOfStruct.fields[0].BinDrakeInput.FormData;
            const itemUnitValue = value_9(FSharpMap__TryFind(formData_1, "xitem-unit"));
            const containerUnitValue = value_9(FSharpMap__TryFind(formData_1, "xcontainer-unit"));
            const containerUnit = (containerUnitValue === "1000") ? ((itemUnitValue === "1") ? "mm" : ((itemUnitValue === "10") ? "cm" : ((itemUnitValue === "1000") ? "m" : "mm"))) : ((containerUnitValue === "10") ? ((itemUnitValue === "1") ? "mm" : ((itemUnitValue === "10") ? "cm" : "cm")) : ((containerUnitValue === "1") ? "mm" : ((containerUnitValue === "ft") ? ((itemUnitValue === "in") ? "in" : containerUnitValue) : containerUnitValue)));
            let itemUnit;
            const ddl = value_9(itemUnitRef.contents);
            const children = ddl.children;
            const loop = (i_mut) => {
                loop:
                while (true) {
                    const i = i_mut;
                    const item = children[i];
                    if (item.value === itemUnitValue) {
                        return item_5(1, split(item.textContent, [":"], undefined, 0)).trim();
                    }
                    else {
                        i_mut = (i + 1);
                        continue loop;
                    }
                    break;
                }
            };
            itemUnit = loop(0);
            const groups = map((tupledArg_1) => {
                let name;
                const key_1 = tupledArg_1[0];
                return LitHelpers_html(fmt`
                        <li>
                            <span data-color=${item_5(0, split(key_1, ["?"], undefined, 0))} class="tag">&nbsp;&nbsp;&nbsp;&nbsp;</span> &nbsp; ${(name = item_5(1, split(key_1, ["?"], undefined, 0)), (name.indexOf("~") >= 0) ? item_5(0, split(name, ["~"], undefined, 0)) : name)}
                            <span class="count">${toText(printf(": x%i"))(tupledArg_1[1])}</span>
                        </li>
                    `);
            }, map((tupledArg) => [tupledArg[0], length(tupledArg[1])], List_groupBy((item_1) => item_1.Item.Tag, items, {
                Equals: (x_10, y_5) => (x_10 === y_5),
                GetHashCode: stringHash,
            })));
            const itemUnit_1 = containerUnit;
            const max = LitHelpers_html(fmt`
                        <p>Max. Length: <span class=number>${op_Division(maxL, multiplier_1)}  ${itemUnit_1}</span>, Width: <span class=number > ${op_Division(maxW, multiplier_1)} ${itemUnit_1}</span>, Height: <span class=number>${op_Division(maxH, multiplier_1)} ${itemUnit_1}</span> </p>
                        <p>Total items in this container: <span class=number> ${totalItems}</span></p>
                        <ul>
                            ${groups}
                        </ul>

                    `);
            if (length(notPut) > 0) {
                return LitHelpers_html(fmt`
                            <p class=error2>Some items were not fit:</p>
                            ${(pageCount > 41) ? LitHelpers_html(fmt`<p>This calculation is more than 41 containers and not supported.</p>`) : Lit_get_nothing()}
                            ${handleItemsUnput(notPut)}
                            ${max}
                        `);
            }
            else if (pageCount > 1) {
                return LitHelpers_html(fmt`
                            <p class=success>All items successfuly placed!</p>
                            <p>CurrentContainer : ${model.CurrentContainerIndex + 1} of ${pageCount} </p>
                            <button type="button" ?disabled=${model.CurrentContainerIndex === 0} 
                                @click=${(_arg) => {
                    dispatch(new Msg(10, []));
                }} >Previous Container</button>
                            <button type="button" ?disabled=${model.CurrentContainerIndex === (pageCount - 1)} 
                                @click=${(_arg_1) => {
                    dispatch(new Msg(9, []));
                }}>Next Container</button>
                            ${max}
                        `);
            }
            else {
                return LitHelpers_html(fmt`
                            <p class=success>All items successfuly placed!</p>
                            ${max}
                        `);
            }
        }
    }
}

