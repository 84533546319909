import { Union, Record } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { Version, Version_$reflection, CreditSystem_Credit_$reflection, Authentication_Email_$reflection } from "../Domain/Model.js";
import { union_type, record_type, bool_type, option_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { fromInt32, toInt64 } from "../fable_modules/fable-library-js.4.19.3/BigInt.js";
import { value } from "../fable_modules/fable-library-js.4.19.3/Option.js";

export class Model extends Record {
    constructor(User, Credit, Subscriber) {
        super();
        this.User = User;
        this.Credit = Credit;
        this.Subscriber = Subscriber;
    }
}

export function Model_$reflection() {
    return record_type("BestFitBox.MVU.UserAndCredits.Model", [], Model, () => [["User", option_type(Authentication_Email_$reflection())], ["Credit", option_type(CreditSystem_Credit_$reflection())], ["Subscriber", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["CreditFetched", "CreditFetchFailed", "GetCreditRequested", "UserSet", "UserLoggedOut"];
    }
}

export function Msg_$reflection() {
    return union_type("BestFitBox.MVU.UserAndCredits.Msg", [], Msg, () => [[["Item", CreditSystem_Credit_$reflection()]], [], [["Item", Version_$reflection()]], [["Item", Authentication_Email_$reflection()]], []]);
}

export class Order extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["None", "GetCredit"];
    }
}

export function Order_$reflection() {
    return union_type("BestFitBox.MVU.UserAndCredits.Order", [], Order, () => [[], [["Item1", Authentication_Email_$reflection()], ["Item2", Version_$reflection()]]]);
}

export function init(userId, subscriber, unitVar) {
    return [new Model(userId, undefined, subscriber), (userId == null) ? (new Order(0, [])) : (new Order(1, [userId, new Version(toInt64(fromInt32(0)))]))];
}

export function update(msg, model) {
    switch (msg.tag) {
        case 0:
            return [new Model(model.User, msg.fields[0], model.Subscriber), new Order(0, [])];
        case 1:
            return [model, new Order(0, [])];
        case 2:
            return [model, (model.User != null) ? (new Order(1, [value(model.User), msg.fields[0]])) : (new Order(0, []))];
        case 3: {
            const userId = msg.fields[0];
            return [new Model(userId, model.Credit, model.Subscriber), new Order(1, [userId, new Version(toInt64(fromInt32(0)))])];
        }
        default:
            return [new Model(undefined, undefined, model.Subscriber), new Order(0, [])];
    }
}

