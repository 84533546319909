import { singleton } from "./fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_ofEffect } from "./fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { curry2 } from "./fable_modules/fable-library-js.4.19.3/Util.js";
import { Program_mkHidden } from "./fable_modules/Fable.Lit.Elmish.1.4.0/Lit.Elmish.fs.js";
import { makeElmish } from "./fable_modules/Fable.Store.1.0.0-beta-005/Store.fs.js";

export function effect(dispatching) {
    return singleton((_update) => ((dispatch) => {
        dispatching(dispatch);
    }));
}

/**
 * Program with user-defined orders instead of usual command.
 * Orders are processed by <code>execute</code> which can dispatch messages,
 * called in place of usual command processing.
 */
export function Program_mkHiddenProgramWithOrderExecute(init, update, execute) {
    const convert = (tupledArg) => [tupledArg[0], Cmd_ofEffect(curry2(execute)(tupledArg[1]))];
    return Program_mkHidden((arg) => convert(init(arg)), (msg, model_1) => convert(update(msg, model_1)));
}

export function Program_mkStoreWithOrderExecute(init, update, dispose, execute) {
    const convert = (tupledArg) => [tupledArg[0], effect(curry2(execute)(tupledArg[1]))];
    return (props) => makeElmish((arg) => convert(init(arg)), (msg, model_1) => convert(update(msg, model_1)), dispose, props);
}

