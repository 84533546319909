import { Union, Record } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, record_type, bool_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";

export class Model extends Record {
    constructor(Show) {
        super();
        this.Show = Show;
    }
}

export function Model_$reflection() {
    return record_type("BestFitBox.MVU.SelectContainer.Model", [], Model, () => [["Show", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SelectContainerClosed", "SelectContainerRequested"];
    }
}

export function Msg_$reflection() {
    return union_type("BestFitBox.MVU.SelectContainer.Msg", [], Msg, () => [[], []]);
}

export class Order extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["None"];
    }
}

export function Order_$reflection() {
    return union_type("BestFitBox.MVU.SelectContainer.Order", [], Order, () => [[]]);
}

export function init() {
    return [new Model(false), new Order()];
}

export function update(msg, model) {
    if (msg.tag === 1) {
        return [new Model(true), new Order()];
    }
    else {
        return [new Model(false), new Order()];
    }
}

