import { Record, Union } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { Calculation_Row, Calculation_BFBInput_$reflection, Calculation_Error_$reflection, Calculation_BFBOutput_$reflection, Calculation_Row_$reflection, Calculation_Multiplier_$reflection } from "../Domain/Model.js";
import { record_type, tuple_type, option_type, class_type, string_type, list_type, int32_type, bool_type, union_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.19.3/Result.js";
import { tail, head, isEmpty, mapIndexed, length, filter, singleton } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { map, append, singleton as singleton_1, collect, delay, toList } from "../fable_modules/fable-library-js.4.19.3/Seq.js";
import { value } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.19.3/String.js";

export class CalculationStatus extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NotStarted", "InProgress"];
    }
}

export function CalculationStatus_$reflection() {
    return union_type("BestFitBox.MVU.BFBInputForm.CalculationStatus", [], CalculationStatus, () => [[], [["Item", Calculation_Multiplier_$reflection()]]]);
}

export class Model extends Record {
    constructor(IsLoggedIn, IsInBulkMode, NextRowIndex, Rows, CurrentForm, Result, PendingCalculation, CalculationStatus) {
        super();
        this.IsLoggedIn = IsLoggedIn;
        this.IsInBulkMode = IsInBulkMode;
        this.NextRowIndex = (NextRowIndex | 0);
        this.Rows = Rows;
        this.CurrentForm = CurrentForm;
        this.Result = Result;
        this.PendingCalculation = PendingCalculation;
        this.CalculationStatus = CalculationStatus;
    }
}

export function Model_$reflection() {
    return record_type("BestFitBox.MVU.BFBInputForm.Model", [], Model, () => [["IsLoggedIn", bool_type], ["IsInBulkMode", bool_type], ["NextRowIndex", int32_type], ["Rows", list_type(Calculation_Row_$reflection())], ["CurrentForm", option_type(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]))], ["Result", option_type(tuple_type(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Calculation_BFBOutput_$reflection(), list_type(Calculation_Error_$reflection())], FSharpResult$2, () => [[["ResultValue", Calculation_BFBOutput_$reflection()]], [["ErrorValue", list_type(Calculation_Error_$reflection())]]]), Calculation_Multiplier_$reflection()))], ["PendingCalculation", option_type(tuple_type(Calculation_BFBInput_$reflection(), Calculation_Multiplier_$reflection()))], ["CalculationStatus", CalculationStatus_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AddRow", "RemoveRow", "CalculationRequested", "Calculated", "CalculationFailed", "SaveDataQueried", "LoggedIn", "LoggedOut", "FileAdded", "FileCleared"];
    }
}

export function Msg_$reflection() {
    return union_type("BestFitBox.MVU.BFBInputForm.Msg", [], Msg, () => [[], [["Item", int32_type]], [["Item1", Calculation_BFBInput_$reflection()], ["Item2", Calculation_Multiplier_$reflection()], ["Item3", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Calculation_BFBOutput_$reflection(), list_type(Calculation_Error_$reflection())], FSharpResult$2, () => [[["ResultValue", Calculation_BFBOutput_$reflection()]], [["ErrorValue", list_type(Calculation_Error_$reflection())]]])]], [["Item", string_type]], [["Item", string_type]], [], [], [], []]);
}

export class Order extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NoOrder", "RequestLogin", "Calculate", "ShowCalcError", "LoadData"];
    }
}

export function Order_$reflection() {
    return union_type("BestFitBox.MVU.BFBInputForm.Order", [], Order, () => [[], [], [["Item", Calculation_BFBInput_$reflection()]], [["Item", string_type]], [["Item", string_type]]]);
}

export function init(isLoggedIn, unitVar) {
    return [new Model(isLoggedIn, false, 1, singleton(new Calculation_Row(0, true, false)), undefined, undefined, undefined, new CalculationStatus(0, [])), new Order(0, [])];
}

export function update(msg_mut, model_mut) {
    update:
    while (true) {
        const msg = msg_mut, model = model_mut;
        switch (msg.tag) {
            case 1: {
                const rows_1 = filter((x) => (x.Index !== msg.fields[0]), model.Rows);
                return [new Model(model.IsLoggedIn, model.IsInBulkMode, model.NextRowIndex, toList(delay(() => collect((matchValue) => singleton_1(new Calculation_Row(matchValue[1].Index, length(rows_1) === (matchValue[0] + 1), length(rows_1) > 1)), mapIndexed((i_1, x_1) => [i_1, x_1], rows_1)))), model.CurrentForm, model.Result, model.PendingCalculation, model.CalculationStatus), new Order(0, [])];
            }
            case 7:
                return init(false, undefined);
            case 5:
                return [model, new Order(4, [msg.fields[0]])];
            case 6: {
                const model_1 = new Model(true, model.IsInBulkMode, model.NextRowIndex, model.Rows, model.CurrentForm, model.Result, model.PendingCalculation, model.CalculationStatus);
                const matchValue_1 = model_1.PendingCalculation;
                if (matchValue_1 == null) {
                    return [model_1, new Order(0, [])];
                }
                else {
                    msg_mut = (new Msg(2, [matchValue_1[0], matchValue_1[1], value(model_1.CurrentForm)]));
                    model_mut = model_1;
                    continue update;
                }
            }
            case 2:
                if (!model.IsLoggedIn) {
                    return [new Model(model.IsLoggedIn, model.IsInBulkMode, model.NextRowIndex, model.Rows, msg.fields[2], model.Result, [msg.fields[0], msg.fields[1]], model.CalculationStatus), new Order(1, [])];
                }
                else {
                    return [new Model(model.IsLoggedIn, model.IsInBulkMode, model.NextRowIndex, model.Rows, model.CurrentForm, undefined, model.PendingCalculation, new CalculationStatus(1, [msg.fields[1]])), new Order(2, [msg.fields[0]])];
                }
            case 4:
                return [new Model(model.IsLoggedIn, model.IsInBulkMode, model.NextRowIndex, model.Rows, model.CurrentForm, undefined, model.PendingCalculation, new CalculationStatus(0, [])), new Order(3, [msg.fields[0]])];
            case 3:
                if (msg.fields[0].tag === 0) {
                    return [new Model(model.IsLoggedIn, model.IsInBulkMode, msg.fields[0].fields[0].BTFInput.NextRowIndex, msg.fields[0].fields[0].BTFInput.Rows, msg.fields[0].fields[0].BTFInput.FormData, [msg.fields[0], msg.fields[0].fields[0].Multiplier], model.PendingCalculation, new CalculationStatus(0, [])), new Order(0, [])];
                }
                else {
                    let matchResult, s_1;
                    if (!isEmpty(msg.fields[0].fields[0])) {
                        if (head(msg.fields[0].fields[0]).tag === 4) {
                            if (isEmpty(tail(msg.fields[0].fields[0]))) {
                                matchResult = 0;
                                s_1 = head(msg.fields[0].fields[0]).fields[0];
                            }
                            else {
                                matchResult = 1;
                            }
                        }
                        else {
                            matchResult = 1;
                        }
                    }
                    else {
                        matchResult = 1;
                    }
                    switch (matchResult) {
                        case 0: {
                            msg_mut = (new Msg(4, [s_1]));
                            model_mut = model;
                            continue update;
                        }
                        default: {
                            msg_mut = (new Msg(4, [toText(printf("%A"))(msg.fields[0].fields[0])]));
                            model_mut = model;
                            continue update;
                        }
                    }
                }
            case 8:
                return [new Model(model.IsLoggedIn, true, model.NextRowIndex, model.Rows, model.CurrentForm, model.Result, model.PendingCalculation, model.CalculationStatus), new Order(0, [])];
            case 9:
                return [new Model(model.IsLoggedIn, false, model.NextRowIndex, model.Rows, model.CurrentForm, model.Result, model.PendingCalculation, model.CalculationStatus), new Order(0, [])];
            default:
                return [new Model(model.IsLoggedIn, model.IsInBulkMode, model.NextRowIndex + 1, toList(delay(() => append(map((row) => (new Calculation_Row(row.Index, false, true)), model.Rows), delay(() => singleton_1(new Calculation_Row(model.NextRowIndex, true, true)))))), model.CurrentForm, model.Result, model.PendingCalculation, model.CalculationStatus), new Order(0, [])];
        }
        break;
    }
}

