import { Record, Union } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { Calculation_Row, CreditSystem_Credit_$reflection, Calculation_Error_$reflection, Calculation_BinDrakeOutput_$reflection, Calculation_Row_$reflection, Calculation_BinDrakeInput_$reflection, Calculation_Multiplier_$reflection } from "../Domain/Model.js";
import { record_type, tuple_type, list_type, bool_type, int32_type, option_type, class_type, string_type, union_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.19.3/Result.js";
import { mapIndexed, length, filter, singleton } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { nonSeeded } from "../fable_modules/fable-library-js.4.19.3/Random.js";
import { collect, singleton as singleton_1, map, append, delay, toList } from "../fable_modules/fable-library-js.4.19.3/Seq.js";
import { value as value_1 } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { FSharpMap__get_Item, FSharpMap__ContainsKey } from "../fable_modules/fable-library-js.4.19.3/Map.js";
import { parse } from "../fable_modules/fable-library-js.4.19.3/Int32.js";
import { rangeDouble } from "../fable_modules/fable-library-js.4.19.3/Range.js";

export class CalculationStatus extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NotStarted", "InProgress"];
    }
}

export function CalculationStatus_$reflection() {
    return union_type("BestFitBox.MVU.BinDrakeInputForm.CalculationStatus", [], CalculationStatus, () => [[], [["Item", Calculation_Multiplier_$reflection()]]]);
}

export class Order extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NoOrder", "RequestLogin", "Calculate", "ShowCalcError", "LoadData", "SubsribeToCreditSystem", "ShowBuyCredits", "TriggerCalculation", "Reset"];
    }
}

export function Order_$reflection() {
    return union_type("BestFitBox.MVU.BinDrakeInputForm.Order", [], Order, () => [[], [["Item1", option_type(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]))], ["Item2", option_type(int32_type)]], [["Item", Calculation_BinDrakeInput_$reflection()]], [["Item", string_type]], [["Item", string_type]], [], [], [], []]);
}

export class Model extends Record {
    constructor(IsLoggedIn, NextRowIndex, RandomNumber, Rows, Result, PendingCalculation, Error$, CurrentForm, CalculateCounter, CalculationStatus, CurrentContainerIndex, PendingOrder, CurrentCredit, Hidden) {
        super();
        this.IsLoggedIn = IsLoggedIn;
        this.NextRowIndex = (NextRowIndex | 0);
        this.RandomNumber = (RandomNumber | 0);
        this.Rows = Rows;
        this.Result = Result;
        this.PendingCalculation = PendingCalculation;
        this.Error = Error$;
        this.CurrentForm = CurrentForm;
        this.CalculateCounter = (CalculateCounter | 0);
        this.CalculationStatus = CalculationStatus;
        this.CurrentContainerIndex = (CurrentContainerIndex | 0);
        this.PendingOrder = PendingOrder;
        this.CurrentCredit = CurrentCredit;
        this.Hidden = Hidden;
    }
}

export function Model_$reflection() {
    return record_type("BestFitBox.MVU.BinDrakeInputForm.Model", [], Model, () => [["IsLoggedIn", bool_type], ["NextRowIndex", int32_type], ["RandomNumber", int32_type], ["Rows", list_type(Calculation_Row_$reflection())], ["Result", option_type(tuple_type(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Calculation_BinDrakeOutput_$reflection(), list_type(Calculation_Error_$reflection())], FSharpResult$2, () => [[["ResultValue", Calculation_BinDrakeOutput_$reflection()]], [["ErrorValue", list_type(Calculation_Error_$reflection())]]]), Calculation_Multiplier_$reflection()))], ["PendingCalculation", option_type(tuple_type(Calculation_BinDrakeInput_$reflection(), Calculation_Multiplier_$reflection()))], ["Error", option_type(string_type)], ["CurrentForm", option_type(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]))], ["CalculateCounter", int32_type], ["CalculationStatus", CalculationStatus_$reflection()], ["CurrentContainerIndex", int32_type], ["PendingOrder", option_type(Order_$reflection())], ["CurrentCredit", option_type(CreditSystem_Credit_$reflection())], ["Hidden", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AddRow", "RemoveRow", "CalculationRequested", "Calculated", "CalculationFailed", "LoggedIn", "LoggedOut", "ProblemOccuredWith", "SaveDataQueried", "NextContainer", "PreviousContainer", "CreditFetched", "SetFormData", "SetFormDataFromExcel", "DialogClosed", "ResetRequested"];
    }
}

export function Msg_$reflection() {
    return union_type("BestFitBox.MVU.BinDrakeInputForm.Msg", [], Msg, () => [[], [["Item", int32_type]], [["Item1", Calculation_BinDrakeInput_$reflection()], ["Item2", Calculation_Multiplier_$reflection()], ["Item3", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Calculation_BinDrakeOutput_$reflection(), list_type(Calculation_Error_$reflection())], FSharpResult$2, () => [[["ResultValue", Calculation_BinDrakeOutput_$reflection()]], [["ErrorValue", list_type(Calculation_Error_$reflection())]]])]], [["Item", string_type]], [], [], [["Item", string_type]], [["Item", string_type]], [], [], [["Item", CreditSystem_Credit_$reflection()]], [["Item", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])]], [["Item", tuple_type(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]), int32_type)]], [], []]);
}

export function init(isLoggedIn, unitVar) {
    let Rows;
    return [(Rows = singleton(new Calculation_Row(0, true, false)), new Model(isLoggedIn, 1, nonSeeded().Next1(10000), Rows, undefined, undefined, undefined, undefined, 0, new CalculationStatus(0, []), 0, undefined, undefined, true)), new Order(5, [])];
}

export function update(msg_mut, model_mut) {
    let n;
    update:
    while (true) {
        const msg = msg_mut, model = model_mut;
        switch (msg.tag) {
            case 14:
                return [model, new Order(0, [])];
            case 11:
                return [new Model(model.IsLoggedIn, model.NextRowIndex, model.RandomNumber, model.Rows, model.Result, model.PendingCalculation, model.Error, model.CurrentForm, model.CalculateCounter, model.CalculationStatus, model.CurrentContainerIndex, model.PendingOrder, msg.fields[0], model.Hidden), new Order(0, [])];
            case 9:
                return [new Model(model.IsLoggedIn, model.NextRowIndex, model.RandomNumber, model.Rows, model.Result, model.PendingCalculation, model.Error, model.CurrentForm, model.CalculateCounter, model.CalculationStatus, model.CurrentContainerIndex + 1, model.PendingOrder, model.CurrentCredit, model.Hidden), new Order(0, [])];
            case 10:
                return [new Model(model.IsLoggedIn, model.NextRowIndex, model.RandomNumber, model.Rows, model.Result, model.PendingCalculation, model.Error, model.CurrentForm, model.CalculateCounter, model.CalculationStatus, model.CurrentContainerIndex - 1, model.PendingOrder, model.CurrentCredit, model.Hidden), new Order(0, [])];
            case 8:
                if (model.IsLoggedIn ? true : (msg.fields[0] === "default-data")) {
                    return [model, new Order(4, [msg.fields[0]])];
                }
                else {
                    return [new Model(model.IsLoggedIn, model.NextRowIndex, model.RandomNumber, model.Rows, model.Result, model.PendingCalculation, model.Error, model.CurrentForm, model.CalculateCounter, model.CalculationStatus, model.CurrentContainerIndex, new Order(4, [msg.fields[0]]), model.CurrentCredit, model.Hidden), new Order(1, [model.CurrentForm, undefined])];
                }
            case 7:
                return [new Model(model.IsLoggedIn, model.NextRowIndex, model.RandomNumber, model.Rows, model.Result, model.PendingCalculation, msg.fields[0], model.CurrentForm, model.CalculateCounter + 1, model.CalculationStatus, model.CurrentContainerIndex, model.PendingOrder, model.CurrentCredit, model.Hidden), new Order(0, [])];
            case 0:
                return [new Model(model.IsLoggedIn, model.NextRowIndex + 1, model.RandomNumber, toList(delay(() => append(map((row) => (new Calculation_Row(row.Index, false, true)), model.Rows), delay(() => singleton_1(new Calculation_Row(model.NextRowIndex, true, true)))))), model.Result, model.PendingCalculation, model.Error, model.CurrentForm, model.CalculateCounter, model.CalculationStatus, model.CurrentContainerIndex, model.PendingOrder, model.CurrentCredit, model.Hidden), new Order(0, [])];
            case 1: {
                const rows_1 = filter((x) => (x.Index !== msg.fields[0]), model.Rows);
                return [new Model(model.IsLoggedIn, model.NextRowIndex, model.RandomNumber, toList(delay(() => collect((matchValue) => singleton_1(new Calculation_Row(matchValue[1].Index, length(rows_1) === (matchValue[0] + 1), length(rows_1) > 1)), mapIndexed((i_1, x_1) => [i_1, x_1], rows_1)))), model.Result, model.PendingCalculation, model.Error, model.CurrentForm, model.CalculateCounter, model.CalculationStatus, model.CurrentContainerIndex, model.PendingOrder, model.CurrentCredit, model.Hidden), new Order(0, [])];
            }
            case 6:
                return init(false, undefined);
            case 5: {
                const model_3 = new Model(true, model.NextRowIndex, model.RandomNumber, model.Rows, model.Result, model.PendingCalculation, model.Error, model.CurrentForm, model.CalculateCounter, model.CalculationStatus, model.CurrentContainerIndex, model.PendingOrder, model.CurrentCredit, model.Hidden);
                const matchValue_1 = model_3.PendingCalculation;
                if (matchValue_1 == null) {
                    const matchValue_2 = model_3.PendingOrder;
                    if (matchValue_2 == null) {
                        return [model_3, new Order(0, [])];
                    }
                    else {
                        return [model_3, matchValue_2];
                    }
                }
                else {
                    msg_mut = (new Msg(2, [matchValue_1[0], matchValue_1[1], value_1(model_3.CurrentForm)]));
                    model_mut = model_3;
                    continue update;
                }
            }
            case 2:
                return [new Model(model.IsLoggedIn, model.NextRowIndex, nonSeeded().Next1(10000), model.Rows, undefined, model.PendingCalculation, model.Error, model.CurrentForm, model.CalculateCounter, new CalculationStatus(1, [msg.fields[1]]), model.CurrentContainerIndex, model.PendingOrder, model.CurrentCredit, model.Hidden), new Order(2, [msg.fields[0]])];
            case 4:
                return [new Model(model.IsLoggedIn, model.NextRowIndex, model.RandomNumber, model.Rows, undefined, model.PendingCalculation, model.Error, model.CurrentForm, model.CalculateCounter, new CalculationStatus(0, []), model.CurrentContainerIndex, model.PendingOrder, model.CurrentCredit, model.Hidden), (msg.fields[0].length > 0) ? (new Order(3, [msg.fields[0]])) : (new Order(0, []))];
            case 3:
                if (msg.fields[0].tag === 0) {
                    return [new Model(model.IsLoggedIn, msg.fields[0].fields[0].BinDrakeInput.NextRowIndex, model.RandomNumber, msg.fields[0].fields[0].BinDrakeInput.Rows, [msg.fields[0], msg.fields[0].fields[0].Multiplier], undefined, undefined, msg.fields[0].fields[0].BinDrakeInput.FormData, model.CalculateCounter, new CalculationStatus(0, []), 0, undefined, model.CurrentCredit, model.Hidden), new Order(0, [])];
                }
                else {
                    msg_mut = (new Msg(4, [toText(printf("%A"))(msg.fields[0].fields[0])]));
                    model_mut = model;
                    continue update;
                }
            case 12:
                return [FSharpMap__ContainsKey(msg.fields[0], "nextRowIndex") ? ((n = (parse(FSharpMap__get_Item(msg.fields[0], "nextRowIndex"), 511, false, 32) | 0), new Model(model.IsLoggedIn, model.NextRowIndex + n, model.RandomNumber, toList(delay(() => map((i_2) => (new Calculation_Row(i_2, i_2 === (n - 1), true)), rangeDouble(0, 1, n - 1)))), model.Result, model.PendingCalculation, model.Error, msg.fields[0], model.CalculateCounter, model.CalculationStatus, model.CurrentContainerIndex, model.PendingOrder, model.CurrentCredit, model.Hidden))) : (new Model(model.IsLoggedIn, model.NextRowIndex, model.RandomNumber, model.Rows, model.Result, model.PendingCalculation, model.Error, msg.fields[0], model.CalculateCounter, model.CalculationStatus, model.CurrentContainerIndex, model.PendingOrder, model.CurrentCredit, model.Hidden)), new Order(0, [])];
            case 13:
                return [new Model(model.IsLoggedIn, model.NextRowIndex + msg.fields[0][1], model.RandomNumber, toList(delay(() => map((i_3) => (new Calculation_Row(i_3, i_3 === (msg.fields[0][1] - 1), true)), rangeDouble(0, 1, msg.fields[0][1] - 1)))), model.Result, model.PendingCalculation, model.Error, msg.fields[0][0], model.CalculateCounter, model.CalculationStatus, model.CurrentContainerIndex, model.PendingOrder, model.CurrentCredit, model.Hidden), new Order(7, [])];
            default:
                return [init(model.IsLoggedIn, undefined)[0], new Order(8, [])];
        }
        break;
    }
}

