import { Hook_createDisposable_3A5B6456, HookComponentAttribute } from "../fable_modules/Fable.Lit.1.4.2/Hook.fs.js";
import { Hook_get_emptyDisposable, Lit_HookContext__HookContext_useEffectOnChange_31A5188A, Hook_getContext_343DAFF1, Hook_useHmr_ZF11B4D8 } from "../fable_modules/Fable.Lit.1.4.2/Hook.fs.js";
import { cleanDSL, changeUrlQueryParams, deleteUrlQueryParams, heading as heading_1, baseLevel as baseLevel_1 } from "../ViewFunctions.js";
import { Lit_refValue_Z4DF72B44, Lit_get_nothing, Lit_mapiUnique, LitHelpers_html } from "../fable_modules/Fable.Lit.1.4.2/Lit.fs.js";
import { printf, toText, substring, fmt } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { some, value as value_12 } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { shareButton, cargoStats, handleResult, helpIcon, isCalculating, darkenColor, distinct20ColorsinRGB, dimCell, formData as formData_3 } from "./Helpers.js";
import { FSharpMap__get_Count, empty, FSharpMap__get_Item, FSharpMap__TryFind } from "../fable_modules/fable-library-js.4.19.3/Map.js";
import { now, toString } from "../fable_modules/fable-library-js.4.19.3/Date.js";
import { LitElementAttribute, Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4 } from "../fable_modules/Fable.Lit.1.4.2/LitElement.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { length, item as item_1 } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { List_groupBy } from "../fable_modules/fable-library-js.4.19.3/Seq2.js";
import { int32ToString, uncurry2, comparePrimitives, defaultOf, numberHash } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { CanvasOutput } from "../MVU/Canvas.js";
import { update, init, Msg } from "../MVU/BinDrakeInputForm.js";
import { Auto_generateBoxedDecoder_Z6670B51, fromString } from "../fable_modules/Thoth.Json.10.2.0/Decode.fs.js";
import { class_type, string_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { onSubmit as onSubmit_1 } from "./Submit.js";
import { parse } from "../fable_modules/fable-library-js.4.19.3/Int32.js";
import { item as item_2 } from "../fable_modules/fable-library-js.4.19.3/Array.js";
import { renderContainer } from "./Container.js";
import { Program_mkHiddenProgramWithOrderExecute } from "../ElmishOrder.js";
import { execute } from "./Execute.js";
import { LitElmishExtensions_useElmish } from "../fable_modules/Fable.Lit.Elmish.1.4.0/Lit.Elmish.fs.js";

const hmr = undefined;

export const view = (new HookComponentAttribute()).Decorate(function (host, model, dispatch) {
    Hook_useHmr_ZF11B4D8(hmr, this);
    const containerUnitRef = Hook_getContext_343DAFF1(this).useRef(() => undefined);
    const itemUnitRef = Hook_getContext_343DAFF1(this).useRef(() => undefined);
    const containerWUnitRef = Hook_getContext_343DAFF1(this).useRef(() => undefined);
    const itemWUnitRef = Hook_getContext_343DAFF1(this).useRef(() => undefined);
    const wakeLock = Hook_getContext_343DAFF1(this).useRef(() => undefined);
    let heading;
    const baseLevel = baseLevel_1(host) | 0;
    heading = ((level) => ((attributes) => ((children) => heading_1(baseLevel, level, attributes, children))));
    const dialogRef = Hook_getContext_343DAFF1(this).useRef(() => undefined);
    const binDrake = LitHelpers_html(fmt`

            <button type=button class="close deny" @click=${(_arg) => {
        value_12(dialogRef.contents).close();
    }}>
                <img src="/assets/icons/icons8-close.svg" alt='close button'>
            </button>
            <div class=ad-info>
                <br/> Subscribe and avoid ads. <br/> Only $2 per month. <br/><br/>
                </div>

            <div class="ad">
                 <img width=200 height="200" src="https://3dpack.ing/assets/icons/favicon-rounded.svg">
                                                
                                                <br/><br/>

                    <div class="subtitle bindrake">
                        <div class="content">Be a subscriber and show your add here for free.
                        </div>

                        </div> <br/>
                   <br/><br/>
                </div>
        </div>
            `);
    const ads = [];
    const formData = formData_3(model);
    let calcName;
    const matchValue = FSharpMap__TryFind(formData, "calc-name");
    calcName = ((matchValue != null) ? matchValue : toString(now(), "yyyy-MM-dd HH:mm:ss"));
    let aiText;
    const matchValue_1 = FSharpMap__TryFind(formData, "aiText");
    aiText = ((matchValue_1 != null) ? matchValue_1 : "");
    let aiTimeStamp;
    const matchValue_2 = FSharpMap__TryFind(formData, "aiTimeStamp");
    aiTimeStamp = ((matchValue_2 != null) ? matchValue_2 : "");
    let loadMode;
    const matchValue_3 = FSharpMap__TryFind(formData, "load-mode");
    loadMode = ((matchValue_3 != null) ? matchValue_3 : "false");
    Lit_HookContext__HookContext_useEffectOnChange_31A5188A(Hook_getContext_343DAFF1(this), model.Error, (e) => {
        if (e != null) {
            Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4(host, "errorOccured", undefined, true, true);
            deleteUrlQueryParams("g", true);
        }
    });
    Lit_HookContext__HookContext_useEffectOnChange_31A5188A(Hook_getContext_343DAFF1(this), [aiText, aiTimeStamp], (_arg_1) => {
        if (aiText.length > 0) {
            Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4(host, "aiTextChanged", aiText, true, true);
            const calcButton = host.shadowRoot.querySelector("bindrake-calculate-button");
            if (loadMode !== "true") {
                calcButton.click();
            }
        }
    });
    Lit_HookContext__HookContext_useEffectOnChange_31A5188A(Hook_getContext_343DAFF1(this), model.CalculationStatus, (e_1) => {
        if (e_1.tag === 0) {
            const pr_2 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => ((wakeLock.contents != null) ? ((value_12(wakeLock.contents).release()).then(() => {
                wakeLock.contents = undefined;
                return Promise.resolve();
            })) : (Promise.resolve()))));
            void pr_2;
        }
        else {
            let pr_1;
            const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => ((navigator.wakeLock.request("screen")))));
            pr_1 = (pr.then((o) => {
                wakeLock.contents = o;
            }));
            void (pr_1.catch((value_2) => {
            }));
        }
    });
    Lit_HookContext__HookContext_useEffectOnChange_31A5188A(Hook_getContext_343DAFF1(this), [model.Result, model.CurrentContainerIndex], (tupledArg) => {
        const result = tupledArg[0];
        let matchResult, m, result_1;
        if (result != null) {
            const copyOfStruct_1 = result[0];
            if (copyOfStruct_1.tag === 0) {
                matchResult = 0;
                m = result[1].fields[0];
                result_1 = copyOfStruct_1.fields[0];
            }
            else {
                matchResult = 1;
            }
        }
        else {
            matchResult = 1;
        }
        switch (matchResult) {
            case 0: {
                if (result_1.ResultKey != null) {
                    const guid = value_12(result_1.ResultKey);
                    changeUrlQueryParams("g", guid, true);
                    localStorage.setItem("last-calc", guid);
                }
                const outputRow_1 = item_1(0, result_1.OutputRows);
                const tupledArg_1 = [new CanvasOutput(item_1(tupledArg[1], List_groupBy((i) => i.ContainerIndex, outputRow_1.ItemsPut, {
                    Equals: (x, y) => (x === y),
                    GetHashCode: numberHash,
                }))[1], outputRow_1.Container), value_12(model.CurrentForm), m];
                Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4(host, "viewIn3d", [tupledArg_1[0], tupledArg_1[1], tupledArg_1[2]], true, true);
                break;
            }
            case 1: {
                break;
            }
        }
    });
    Hook_getContext_343DAFF1(this).useEffect(() => {
        const colored = host.shadowRoot.querySelectorAll("[data-color]");
        for (let i_1 = 0; i_1 <= (colored.length - 1); i_1++) {
            const el = colored[i_1];
            const color = el.getAttribute("data-color");
            el.style.backgroundColor = color;
        }
    });
    Hook_getContext_343DAFF1(this).useEffectOnce(() => {
        const loggedIn = (e_2) => {
            dispatch(new Msg(5, []));
        };
        const loggedOut = (e_3) => {
            dispatch(new Msg(6, []));
            deleteUrlQueryParams("g", true);
        };
        const excelUploaded = (e_4) => {
            const customEvent = e_4;
            dispatch(new Msg(13, [customEvent.detail]));
        };
        const containerSelect = (e_6) => {
            const customEvent_1 = e_6;
            const detail_1 = customEvent_1.detail;
            (host.shadowRoot.querySelector("[name=container-length]")).value = FSharpMap__get_Item(detail_1, "length");
            (host.shadowRoot.querySelector("[name=container-width]")).value = FSharpMap__get_Item(detail_1, "width");
            (host.shadowRoot.querySelector("[name=container-height]")).value = FSharpMap__get_Item(detail_1, "height");
            (host.shadowRoot.querySelector("[name=container-maxWeight]")).value = FSharpMap__get_Item(detail_1, "weight");
            (host.shadowRoot.querySelector("[name=xcontainer-unit]")).value = FSharpMap__get_Item(detail_1, "dim-unit");
            (host.shadowRoot.querySelector("[name=wcontainer-unit]")).value = FSharpMap__get_Item(detail_1, "w-unit");
            (host.shadowRoot.querySelector("[name=xitem-unit]")).value = FSharpMap__get_Item(detail_1, "dim-item-unit");
            (host.shadowRoot.querySelector("[name=witem-unit]")).value = FSharpMap__get_Item(detail_1, "w-unit");
        };
        document.addEventListener("containerSelected", containerSelect);
        document.addEventListener("excelUploaded", excelUploaded);
        document.addEventListener("loggedIn", loggedIn);
        document.addEventListener("loggedOut", loggedOut);
        document.addEventListener("keydown", (e_5) => {
            const matchValue_4 = dialogRef.contents;
            if (matchValue_4 != null) {
                const dialogRef_1 = matchValue_4;
                if (dialogRef_1.open) {
                    if (e_5.key === "Escape") {
                        e_5.preventDefault();
                    }
                }
            }
        });
        return Hook_createDisposable_3A5B6456(() => {
            document.removeEventListener("containerSelected", containerSelect);
            document.removeEventListener("excelUploaded", excelUploaded);
            document.removeEventListener("loggedIn", loggedIn);
            document.removeEventListener("loggedOut", loggedOut);
        });
    });
    Hook_getContext_343DAFF1(this).useEffectOnce(() => {
        try {
            const window$ = window.top;
            const matchValue_5 = window$.location.search;
            if (matchValue_5.indexOf("?g=") === 0) {
                dispatch(new Msg(8, [substring(window.location.search, 3)]));
            }
            else {
                dispatch(new Msg(8, ["default-data"]));
            }
            const formData_1 = window.localStorage.getItem("bindrake-form-data");
            let formData_2;
            if (formData_1 === defaultOf()) {
                formData_2 = empty({
                    Compare: comparePrimitives,
                });
            }
            else {
                const matchValue_6 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]), undefined, undefined)), formData_1);
                if (matchValue_6.tag === 1) {
                    console.error(some(matchValue_6.fields[0]));
                    formData_2 = empty({
                        Compare: comparePrimitives,
                    });
                }
                else {
                    formData_2 = matchValue_6.fields[0];
                }
            }
            window.localStorage.removeItem("bindrake-form-data");
            dispatch(new Msg(12, [formData_2]));
        }
        catch (e_8) {
            console.error(some(e_8));
        }
        return Hook_get_emptyDisposable();
    });
    const onSubmit = onSubmit_1(host, model, dispatch);
    const itemDimCell = (minValue, maxValue, defaultValue, name, index_1) => dimCell(formData, "item", minValue, maxValue, defaultValue, name, index_1);
    const itemRows = Lit_mapiUnique((row) => int32ToString(row.Index, 10), (rowIndex, item) => {
        let copyOfStruct_3;
        const removeButton = item.ShowRemove ? LitHelpers_html(fmt`
                        <span>
                            <button type="button" aria-label="Remove current row" class=deny @click='${(e_9) => {
            let copyOfStruct_2;
            let htmlElement;
            htmlElement = ((copyOfStruct_2 = e_9, copyOfStruct_2.target));
            const closest = value_12(htmlElement.closest("tr"));
            console.log(some(closest));
            closest.classList.add("is-deleting");
            window.setTimeout((_arg_3) => {
                dispatch(new Msg(1, [item.Index]));
            }, 500);
        }}'>-</button>
                            <tool-tip inert role="tooltip" tip-position="top">
                                Removes current row
                            </tool-tip>
                        </span>
                        `) : LitHelpers_html(fmt`
                        <span>
                            <button type="button" aria-label="Remove current row"  class=deny aria-disabled="true"  disabled>-</button>
                        </span>
                        `);
        const addButton = item.ShowAdd ? LitHelpers_html(fmt`
                        <span>
                            <button type="button" class=good aria-label="Add a new row" @click='${(e_10) => {
            dispatch(new Msg(0, []));
        }}'>+</button>
                            <tool-tip inert role="tooltip" tip-position="top">
                                Adds a new row
                            </tool-tip>
                        </span>
                        `) : Lit_get_nothing();
        const itemIndex = int32ToString(item.Index);
        let color_1;
        const defaultColor = (parse(itemIndex, 511, false, 32) < 16) ? item_2(parse(itemIndex, 511, false, 32), distinct20ColorsinRGB) : darkenColor((~~(parse(itemIndex, 511, false, 32) / 16) % 3) * 0.2, item_2(parse(itemIndex, 511, false, 32) % 16, distinct20ColorsinRGB));
        const matchValue_7 = model.CurrentForm;
        if (matchValue_7 != null) {
            const matchValue_8 = FSharpMap__TryFind(matchValue_7, toText(printf("item-%s-color"))(itemIndex));
            color_1 = ((matchValue_8 != null) ? matchValue_8 : defaultColor);
        }
        else {
            color_1 = defaultColor;
        }
        const checkboxCheck = (name_1, def) => {
            const matchValue_9 = model.CurrentForm;
            let matchResult_1, form_2;
            if (matchValue_9 != null) {
                if (FSharpMap__get_Count(matchValue_9) > 0) {
                    matchResult_1 = 0;
                    form_2 = matchValue_9;
                }
                else {
                    matchResult_1 = 1;
                }
            }
            else {
                matchResult_1 = 1;
            }
            switch (matchResult_1) {
                case 0: {
                    const matchValue_10 = FSharpMap__TryFind(form_2, toText(printf("item-%s-%s"))(itemIndex)(name_1));
                    if (matchValue_10 != null) {
                        const v_5 = matchValue_10;
                        return true;
                    }
                    else if (FSharpMap__TryFind(form_2, toText(printf("item-%s-color"))(itemIndex)) == null) {
                        return def;
                    }
                    else {
                        return false;
                    }
                }
                default:
                    return def;
            }
        };
        const keepTop = checkboxCheck("keeptop", false);
        const keepBottom = checkboxCheck("keepbottom", false);
        const stack = checkboxCheck("stack", true);
        let itemName;
        const defaultName = "Item " + ((copyOfStruct_3 = (item.Index + 1), int32ToString(copyOfStruct_3)));
        const matchValue_11 = model.CurrentForm;
        if (matchValue_11 != null) {
            const matchValue_12 = FSharpMap__TryFind(matchValue_11, toText(printf("item-%s-name"))(itemIndex));
            itemName = ((matchValue_12 != null) ? matchValue_12 : defaultName);
        }
        else {
            itemName = defaultName;
        }
        return LitHelpers_html(fmt`
            <tr>
                <td class=name>
                    <input class="name" name='${toText(printf("item-%s-name"))(itemIndex)}' title=name required type=text .value='${itemName}'></td>
                </td>
                ${itemDimCell("0.01", "20000", undefined, "length", itemIndex)}
                ${itemDimCell("0.01", "20000", undefined, "width", itemIndex)}
                ${itemDimCell("0.01", "20000", undefined, "height", itemIndex)}
                ${itemDimCell("0", "200000", 0, "weight", itemIndex)}
                ${itemDimCell("1", "2000", 1, "quantity", itemIndex)}
                ${itemDimCell("1", "2000", 1, "priority", itemIndex)}
                <td>
                    <input name='${toText(printf("item-%s-color"))(itemIndex)}' title=color required type=color .value='${color_1}'></td>
                </td>
                <td>
                    <input name='${toText(printf("item-%s-keeptop"))(itemIndex)}' title=keeptop ?checked=${keepTop} type='checkbox'>
                </td>
                <td>
                    <input name='${toText(printf("item-%s-keepbottom"))(itemIndex)}' title=keepbottom ?checked=${keepBottom}  type='checkbox'>
                </td>
                <td>
                <input name='${toText(printf("item-%s-stack"))(itemIndex)}' title=stack checked type='checkbox' ?checked=${stack} >
                </td>
                <td class="action">
                  ${removeButton}
                </td>
                <td class="action">
                    ${addButton}
                </td>
   
            </tr>
        `);
    }, model.Rows);
    const calculateButton = LitHelpers_html(fmt`
                <label> Calculation name
                    <input name="calc-name" .value=${calcName} placeholder="My latest cargo to middle earth" required>
                </label>

                <bindrake-calculate-button  isbusy='${isCalculating(model)}' timer=240></bindrake-calculate-button>
                `);
    let defaultMultiContainerValue;
    const matchValue_13 = FSharpMap__TryFind(formData, "container-mode");
    defaultMultiContainerValue = ((matchValue_13 != null) ? matchValue_13 : "Single");
    let defaultxitemunit;
    const matchValue_14 = FSharpMap__TryFind(formData, "xitem-unit");
    defaultxitemunit = ((matchValue_14 != null) ? matchValue_14 : "10");
    let defaultwitemunit;
    const matchValue_15 = FSharpMap__TryFind(formData, "witem-unit");
    defaultwitemunit = ((matchValue_15 != null) ? matchValue_15 : "1000");
    let defaultquantityMode;
    const matchValue_16 = FSharpMap__TryFind(formData, "quantity-mode");
    defaultquantityMode = ((matchValue_16 != null) ? matchValue_16 : "Normal");
    let defaultStackingMode;
    const matchValue_17 = FSharpMap__TryFind(formData, "stack-mode");
    defaultStackingMode = ((matchValue_17 != null) ? matchValue_17 : "Allow");
    let defaultCalcMode;
    const matchValue_18 = FSharpMap__TryFind(formData, "calc-mode");
    defaultCalcMode = ((matchValue_18 != null) ? matchValue_18 : "MinimizeLength");
    let defaultSpeedMode;
    const matchValue_19 = FSharpMap__TryFind(formData, "speed-mode");
    defaultSpeedMode = ((matchValue_19 != null) ? matchValue_19 : "Normal");
    const ad = item_2(model.RandomNumber % ads.length, ads);
    return LitHelpers_html(fmt`
        <form aria-label="Calculation form" id="calc-form" autocomplete="off" ?novalidate=${host.hasAttribute("aimode")} @submit='${onSubmit}'>
            ${renderContainer(model, containerUnitRef, containerWUnitRef)}

            <fieldset>
                <legend>Item dimensions</legend>
                <div id="fill-button-panel">
                    <bindrake-fillfromexcel></bindrake-fillfromexcel>
                    <button type="reset" @click=${(_arg_4) => {
        dispatch(new Msg(15, []));
    }}> Reset Form </button>
                </div>
                <input name="aiText" .value='${aiText}' type="hidden">
                <input name="aiTimeStamp" .value='${aiTimeStamp}' type="hidden">

                <table class=item aria-live="polite" aria-relevant="additions removals" summary="Item dimensions">
                    <colgroup>
                        <col class=name>
                        <col class='dim'/>
                        <col class='dim' />
                        <col class='dim'/>
                        <col />
                        <col class="quant"/>
                        <col class="priority"/>
                        <col />
                        <col />
                        <col />
                        <col />
                        <col class="action"/>
                        <col class="action" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th class="name"><div>Name<span> ${helpIcon}  
                                        <tool-tip inert role="tooltip" tip-position="top">
                                            You can specify a name. 
                                            When you click the item in 3D, the name 
                                            will be shown above.
                                        </tool-tip> 
                                    </span>
                                </div> 
                            </th>
                            <th>Length</th>
                            <th>Width</th>
                            <th>Height</th>
                            <th>
                                <div>Weig/pc<span> ${helpIcon}  
                                        <tool-tip inert role="tooltip" tip-position="top">
                                            Weight per single pc.
                                            Leave it zero if not needed.
                                        </tool-tip> 
                                    </span>
                                </div> 
                            </th>
                            <th>
                                <div> 
                                    Quantity
                                    <span> ${helpIcon}  
                                        <tool-tip inert role="tooltip" tip-position="top">
                                            Quantity for this item.
                                            The range is 1 to 2000. 
                                        </tool-tip> 
                                    </span>
                                </div> 
                            </th>
                            <th>
                                <div> 
                                    Pr
                                    <span> ${helpIcon}  
                                        <tool-tip inert role="tooltip" tip-position="top">
                                            Priority for this item. Higher numbers will be packed first.
                                            If you want an item to be loaded last, give it a lower number.
                                            This setting can also be combined with Stack Different Priority option below.
                                            The range is 1 to 100. 
                                        </tool-tip> 
                                    </span>
                                </div> 
                            </th>
                            <th>
                                <div> 
                                    🎨 
                                    <span> ${helpIcon}  
                                        <tool-tip inert role="tooltip" tip-position="top">
                                            Color for this item. It will be displayed in 3D.
                                            You can change the color by clicking on it. 
                                        </tool-tip> 
                                    </span>
                                </div> 
                            </th>
                            <th>
                                <div> 
                                    ⬆ 
                                    <span> ${helpIcon}  
                                        <tool-tip inert role="tooltip" tip-position="top">
                                            If checked, locks the item’s vertical orientation and 
                                                prevents it from tilting sideways. Also recommended
                                                for large number of items as it makes cargo tidier.
                                        </tool-tip> 
                                    </span>
                                </div> 
                            </th>
                            <th>
                                <div> 
                                    ⬇ 
                                    <span> ${helpIcon}  
                                        <tool-tip inert role="tooltip" tip-position="top">
                                        If checked, the item rests on the ground level.
                                        Recommended for heavy items.
                                        </tool-tip> 
                                    </span>
                                </div> 
                            </th>
                            <th>
                                <div> 
                                    📚 
                                    <span> ${helpIcon}  
                                        <tool-tip inert role="tooltip" tip-position="top">
                                            Allow stacking, default on.
                                            If unchecked, other items cannot be stacked on top of the item.
                                            The item can still be placed on top of other items. To prevent that, check ⬇ as well.
                                        </tool-tip> 
                                    </span>
                                </div> 
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        ${itemRows}
                        <tr>
                            <td class="name"></td>
                            <td colspan="3">
                                <select ${Lit_refValue_Z4DF72B44(itemUnitRef)} title="Item units" name='xitem-unit'>
                                    <option  ?selected=${defaultxitemunit === "1"} value="1">units: mm</option>
                                    <option ?selected=${defaultxitemunit === "10"} value="10">units: cm</option>
                                    <option ?selected=${defaultxitemunit === "1000"} value="1000">units: m</option>
                                    <option  ?selected=${defaultxitemunit === "ft"} value="ft">units: ft</option>
                                    <option  ?selected=${defaultxitemunit === "in"} value="in">units: in</option>
                                </select>
                            </td>
                            <td>
                                <select ${Lit_refValue_Z4DF72B44(itemWUnitRef)} title="Item weight unit" name='witem-unit'>
                                    <option  ?selected=${defaultwitemunit === "1"} value="1">gr</option>
                                    <option  ?selected=${defaultwitemunit === "1000"} value="1000">kg</option>
                                    <option  ?selected=${defaultwitemunit === "1000000"} value="1000000">metric tonnes</option>
                                    <option  ?selected=${defaultwitemunit === "oz"} value="oz">oz</option>
                                    <option  ?selected=${defaultwitemunit === "lbs"} value="lbs">lbs</option>
                                </select>
                            </td>
                            <td colspan="6"></td>
                            <td colspan="2"> 
                            </td>
                            
                               
                        </tr>
                    </tbody>
                </table>
                <div class="ddl-panel">
                    <div class="ddl-group">
                        <div class="mode-container container">
                            <label for="container-mode">
                                <div> 
                                    Container Mode
                                    <span> ${helpIcon}  
                                        <tool-tip inert role="tooltip" tip-position="top">
                                            Single mode will try to force everything into one container.
                                            Multiple mode will try to use as many containers as needed.
                                        </tool-tip> 
                                    </span>
                                    :
                                </div>
                            </label>
                            <select name="container-mode" id="container-mode">
                                <option value="Single" ?selected=${defaultMultiContainerValue === "Single"}>Single</option>
                                <option value="Multiple" ?selected=${defaultMultiContainerValue === "Multiple"}>Multiple</option>
                            </select>
                        </div>
                        <div class="mode-container calc">
                            <label for="calc-mode">
                                <div> 
                                    Load Mode
                                    <span> ${helpIcon}  
                                        <tool-tip inert role="tooltip" tip-position="top">
                                            <p>Back to Front will try to load the container from back to front,
                                            giving you max free length.</p>
                                            <br>
                                            <p>
                                            Bottom to Top will try to load the container from bottom to top,
                                            giving you max free height.</p>
                                        </tool-tip> 
                                    </span>
                                    :
                                </div>

                            </label>
                            <select name="calc-mode" id="calc-mode">
                                <option value="MinimizeLength" .selected=${defaultCalcMode === "MinimizeLength"}>Back To Front</option>
                                <option value="MinimizeHeight" .selected=${defaultCalcMode === "MinimizeHeight"}>Bottom To Top</option>
                            </select>
                        </div>
                        <div class="mode-container calc">
                            <label for="speed-mode">
                                <div> 
                                    Algorithm
                                    <span> ${helpIcon}  
                                        <tool-tip inert role="tooltip" tip-position="top">
                                            <p> Adjusts algorithm. Aggressive is same as Optimal but spends more time on packing.  </p>
                                            <p> Compact is a new experimental algorithm, works differently, useful when you have different sizes of boxes.</p>
                                        </tool-tip> 
                                    </span>
                                    :
                                </div>

                            </label>
                            <select name="speed-mode" id="speed-mode">
                                <option value="Normal" .selected=${defaultSpeedMode === "Normal"}>Optimal</option>
                                <option value="Slow" .selected=${defaultSpeedMode === "Slow"}>Aggressive</option>
                                <option value="Fast" .selected=${defaultSpeedMode === "Fast"}>Compact</option>
                            </select>
                        </div>
                    </div>

                    <div class="ddl-group">
                        <div class="mode-container calc">
                            <label for="quantity-mode">
                                <div> 
                                    Quantity Mode
                                    <span> ${helpIcon}  
                                        <tool-tip inert role="tooltip" tip-position="top">
                                            <p>Quantity will try to load the container with the exact number of items.</p>
                                            <p>Fill Container will find out the maximum number of items by ignoring the quantity.</p>
                                            <p>Fill Container mode only works when you have a single item.</p>
                                        </tool-tip> 
                                    </span>
                                    :
                                </div>

                            </label>
                            <select ?disabled=${length(model.Rows) > 1} title="quantity mode" name='quantity-mode'>
                                <option ?selected=${(length(model.Rows) > 1) ? true : (defaultquantityMode === "Normal")} value="Normal">Quantity</option>
                                <option ?selected=${(length(model.Rows) === 1) && (defaultquantityMode === "Max")} value="Max">Fill Container</option>
                            </select>
                        </div>
                        <div class="mode-container calc">
                            <label for="calc-mode">
                                <div> 
                                    Group Priority
                                    <span> ${helpIcon}  
                                        <tool-tip inert role="tooltip" tip-position="top">
                                            <p>If you select yes then items with different priority numbers won't be stacked together.</p>
                                            <p>Yes is a good option for separating different items based on their priority number</p>
                                        </tool-tip> 
                                    </span>
                                    :
                                </div>
                            </label>
                            <select name="stack-mode" id="stack-mode">
                                <option value="Allow" ?selected=${defaultStackingMode === "Allow"}>No</option>
                                <option value="Disallow" ?selected=${defaultStackingMode === "Disallow"}>Yes</option>
                            </select>
                        </div>
                    </div>
                </div>
            </fieldset>
            <div class=form-button-wrapper>
            ${calculateButton}
            </div>

        </form>
        <fieldset>
                <legend>Results</legend>
                <output>
                    <div>
                        ${handleResult(model, itemUnitRef, dispatch)}
                    </div>
                    <div>
                        ${cargoStats(model, itemUnitRef, containerUnitRef, itemWUnitRef, containerWUnitRef)}
                    </div>
                    <div>
                        ${shareButton(model)}
                    </div>
                </output>
            </fieldset>
        <dialog ${Lit_refValue_Z4DF72B44(dialogRef)}>
        
           ${ad}
        </dialog>
    `);
});

export const InputForm = (new LitElementAttribute("bindrake-input-form")).Decorate(function () {
    Hook_useHmr_ZF11B4D8(hmr, this);
    const host = (this).init((arg) => {
        let a;
        a = (arg.useShadowDom = true);
        return Promise.resolve(undefined);
    })[0];
    cleanDSL(host);
    const userAttribute = document.querySelector("bfb-login").getAttribute("user");
    const isLoggedIn = (userAttribute === defaultOf()) ? false : (!(userAttribute === ""));
    const program = Program_mkHiddenProgramWithOrderExecute(() => init(isLoggedIn, undefined), update, (order, dispatch) => {
        execute(host, order, dispatch);
    });
    const patternInput_1 = LitElmishExtensions_useElmish(Hook_getContext_343DAFF1(this), () => program);
    return view(host, patternInput_1[0], patternInput_1[1]);
});

export function register() {
}

