import { Record, Union } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, bool_type, option_type, union_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Authentication_Email_$reflection } from "../Domain/Model.js";

export class Mode extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AI", "Manual"];
    }
}

export function Mode_$reflection() {
    return union_type("BestFitBox.MVU.ManualEntry.Mode", [], Mode, () => [[], []]);
}

export class Model extends Record {
    constructor(User, Subscriber, Mode) {
        super();
        this.User = User;
        this.Subscriber = Subscriber;
        this.Mode = Mode;
    }
}

export function Model_$reflection() {
    return record_type("BestFitBox.MVU.ManualEntry.Model", [], Model, () => [["User", option_type(Authentication_Email_$reflection())], ["Subscriber", bool_type], ["Mode", Mode_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UserSet", "UserLoggedOut", "ModeSet"];
    }
}

export function Msg_$reflection() {
    return union_type("BestFitBox.MVU.ManualEntry.Msg", [], Msg, () => [[["Item", Authentication_Email_$reflection()]], [], [["Item", Mode_$reflection()]]]);
}

export class Order extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["None", "ManualEffect", "AIEffect"];
    }
}

export function Order_$reflection() {
    return union_type("BestFitBox.MVU.ManualEntry.Order", [], Order, () => [[], [], []]);
}

export function init(userId, subscriber, unitVar) {
    return [new Model(userId, subscriber, new Mode(0, [])), new Order(0, [])];
}

export function update(msg, model) {
    switch (msg.tag) {
        case 0:
            return [new Model(msg.fields[0], model.Subscriber, model.Mode), new Order(0, [])];
        case 2: {
            const mode = msg.fields[0];
            return [new Model(model.User, model.Subscriber, mode), (mode.tag === 1) ? (new Order(1, [])) : (new Order(2, []))];
        }
        default:
            return [new Model(undefined, model.Subscriber, model.Mode), new Order(0, [])];
    }
}

