import { LitHookElement$1, Prop$1, LitElementInit$1__get_InitPromise, LitElementInit$1_$ctor } from "../fable_modules/Fable.Lit.1.4.2/LitElement.fs.js";
import { toArray } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { zip, iterate } from "../fable_modules/fable-library-js.4.19.3/Seq.js";
import { printf, toFail } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { class_type, obj_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { toArray as toArray_1, some } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { Attribute } from "../fable_modules/fable-library-js.4.19.3/Types.js";

export function isNotNull(x) {
    return !(x == null);
}

export const definedElements = new Set([]);

export class FormElementAttribute extends Attribute {
    constructor(name) {
        super();
        this.name = name;
    }
    Decorate(renderFn) {
        const this$ = this;
        const config = LitElementInit$1_$ctor();
        if (renderFn.length > 0) {
            throw new Error("Render function for LitElement cannot take arguments");
        }
        try {
            renderFn.apply(config, []);
        }
        catch (matchValue) {
        }
        if (LitElementInit$1__get_InitPromise(config) == null) {
            throw new Error("LitElement must return a Promise from its constructor");
        }
        let pr_1;
        const pr = LitElementInit$1__get_InitPromise(config);
        pr_1 = (pr.then(() => {
            const config_1 = config;
            const styles = isNotNull(config_1.styles) ? toArray(config_1.styles) : undefined;
            let patternInput_1;
            if (isNotNull(config_1.props)) {
                const propsValues = [];
                const propsOptions = {};
                iterate((tupledArg) => {
                    const k = tupledArg[0];
                    let patternInput;
                    const matchValue_1 = tupledArg[1];
                    let matchResult, v_1;
                    if (matchValue_1 instanceof Prop$1) {
                        matchResult = 0;
                        v_1 = matchValue_1;
                    }
                    else if (matchValue_1 instanceof Prop$1) {
                        matchResult = 0;
                        v_1 = matchValue_1;
                    }
                    else if (matchValue_1 instanceof Prop$1) {
                        matchResult = 0;
                        v_1 = matchValue_1;
                    }
                    else {
                        matchResult = 1;
                    }
                    switch (matchResult) {
                        case 0: {
                            patternInput = [v_1.defaultValue, v_1.options];
                            break;
                        }
                        default:
                            patternInput = toFail(printf("Invalid prop definition %A"))(obj_type);
                    }
                    const defVal = patternInput[0];
                    propsOptions[k] = patternInput[1];
                    if (!(defVal == null)) {
                        void (propsValues.push([k, defVal]));
                    }
                }, zip(Object.keys(config_1.props), Object.values(config_1.props)));
                patternInput_1 = [some(propsOptions), (this$_1) => {
                    iterate((tupledArg_1) => {
                        this$_1[tupledArg_1[0]] = tupledArg_1[1];
                    }, propsValues);
                }];
            }
            else {
                patternInput_1 = [undefined, (_arg_1) => {
                }];
            }
            const classExpr = class extends LitHookElement$1 {
        static get formAssociated() { return true; }
        constructor() { super(patternInput_1[1]) }
        get renderFn() { return renderFn }
    };
            iterate((props) => {
                Object.defineProperty(classExpr, "properties", { get: (() => props) });
            }, toArray_1(patternInput_1[0]));
            iterate((styles_1) => {
                Object.defineProperty(classExpr, "styles", { get: (() => styles_1) });
            }, toArray_1(styles));
            if (!config_1.useShadowDom) {
                classExpr.prototype.createRenderRoot = function() {
                        return this;
                    };
            }
            customElements.define(this$.name, classExpr);
        }));
        void (pr_1.catch((er) => {
            console.error(some(er));
        }));
        return () => {
            throw new Error(`${this$.name} is not immediately callable, it must be created in HTML`);
        };
    }
}

export function FormElementAttribute_$reflection() {
    return class_type("BestFitBox.Client.Components.CustomLit.FormElementAttribute", undefined, FormElementAttribute, class_type("Fable.Core.JS.DecoratorAttribute"));
}

export function FormElementAttribute_$ctor_Z721C83C5(name) {
    return new FormElementAttribute(name);
}

