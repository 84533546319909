import { Remoting_buildProxy_64DC51C } from "../fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder } from "../fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { Calculation_$reflection, Route_builder } from "../Domain/API.js";
import { LitElementAttribute, Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4 } from "../fable_modules/Fable.Lit.1.4.2/LitElement.fs.js";
import { fromContinuations, startImmediate } from "../fable_modules/fable-library-js.4.19.3/Async.js";
import { singleton } from "../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { tail, head, isEmpty } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { update, init, Msg } from "../MVU/FillFromExcel.js";
import { Hook_createDisposable_3A5B6456, HookComponentAttribute } from "../fable_modules/Fable.Lit.1.4.2/Hook.fs.js";
import { Lit_HookContext__HookContext_useEffectOnChange_31A5188A, Hook_getContext_343DAFF1, Hook_useHmr_ZF11B4D8 } from "../fable_modules/Fable.Lit.1.4.2/Hook.fs.js";
import { value as value_1 } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { Lit_refValue_Z4DF72B44, LitHelpers_html } from "../fable_modules/Fable.Lit.1.4.2/Lit.fs.js";
import { fmt } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { cleanDSL } from "../ViewFunctions.js";
import { defaultOf } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { Program_mkHiddenProgramWithOrderExecute } from "../ElmishOrder.js";
import { LitElmishExtensions_useElmish } from "../fable_modules/Fable.Lit.Elmish.1.4.0/Lit.Elmish.fs.js";

const hmr = undefined;

export const Server_api = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Route_builder, RemotingModule_createApi()), Calculation_$reflection());

export function execute(host, order, dispatch) {
    switch (order.tag) {
        case 2: {
            Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4(host, "loginRequested", undefined, true, true);
            break;
        }
        case 1: {
            startImmediate(singleton.Delay(() => singleton.Bind(Server_api.UploadExcel(order.fields[0]), (_arg) => {
                const res = _arg;
                return singleton.Combine((res.tag === 1) ? (!isEmpty(res.fields[0]) ? ((head(res.fields[0]).tag === 3) ? (isEmpty(tail(res.fields[0])) ? ((Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4(host, "buyCreditsRequested", head(res.fields[0]).fields[0], true, true, true), (dispatch(new Msg(1, [])), singleton.Zero()))) : ((window.showError("an error occured"), singleton.Zero()))) : ((window.showError("an error occured"), singleton.Zero()))) : ((window.showError("an error occured"), singleton.Zero()))) : (((host.shadowRoot.querySelector("dialog")).close(), (Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4(host, "excelUploaded", [res.fields[0][0], res.fields[0][1]], true, true, true), singleton.Zero()))), singleton.Delay(() => singleton.Return(undefined)));
            })));
            break;
        }
        default:
            undefined;
    }
}

export const view = (new HookComponentAttribute()).Decorate(function (host, model, dispatch) {
    Hook_useHmr_ZF11B4D8(hmr, this);
    const dialogRef = Hook_getContext_343DAFF1(this).useRef(() => undefined);
    Lit_HookContext__HookContext_useEffectOnChange_31A5188A(Hook_getContext_343DAFF1(this), model.Show, (_arg) => {
        if (model.Show) {
            value_1(dialogRef.contents).showModal();
        }
        else {
            value_1(dialogRef.contents).close();
        }
    });
    Hook_getContext_343DAFF1(this).useEffectOnce(() => {
        const loggedIn = (e) => {
            dispatch(new Msg(4, []));
        };
        const loggedOut = (e_1) => {
            dispatch(new Msg(5, []));
        };
        document.addEventListener("loggedIn", loggedIn);
        document.addEventListener("loggedOut", loggedOut);
        return Hook_createDisposable_3A5B6456(() => {
            document.removeEventListener("loggedIn", loggedIn);
            document.removeEventListener("loggedOut", loggedOut);
        });
    });
    Hook_getContext_343DAFF1(this).useEffectOnce(() => {
        const dialogCloseHandler = (e_2) => {
            dispatch(new Msg(1, []));
        };
        value_1(dialogRef.contents).addEventListener("close", dialogCloseHandler);
        return Hook_createDisposable_3A5B6456(() => {
            value_1(dialogRef.contents).removeEventListener("close", dialogCloseHandler);
        });
    });
    const formContent = LitHelpers_html(fmt`
         <form @submit=${(e_4) => {
        const e_3 = e_4;
        startImmediate(singleton.Delay(() => {
            e_3.preventDefault();
            const form = e_3.currentTarget;
            const files = (form["excel"]).files;
            return singleton.Bind(fromContinuations((tupledArg) => {
                const cont = tupledArg[0];
                if (files.length > 0) {
                    const file = files[0];
                    const reader = new FileReader();
                    reader.onload = ((_arg_3) => {
                        cont(reader.result);
                    });
                    reader.readAsDataURL(file);
                }
                else {
                    cont(undefined);
                }
            }), (_arg_4) => {
                const excelString = _arg_4;
                if (excelString != null) {
                    dispatch(new Msg(2, [excelString]));
                    return singleton.Zero();
                }
                else {
                    return singleton.Zero();
                }
            });
        }));
    }}>
            <ol>
                <li> First download the <a href="/assets/Bindrake.xlsx" download="Bindrake.xlsx">Template</a> and fill. Then upload below.
                <li>
                    <label for="file">Select the Excel file to upload</label>
                    <input type="file" id="excel" name="excel" accept=".xlsx">
                </li>
                <li>
                    <button type="submit">Upload</button>
                </li>
            </ol> 
         </form>
        `);
    return LitHelpers_html(fmt`
         <link rel="stylesheet" href="/css/excel.css?v=202407121659">
        <div>
            <span>
                <button type="button" class=good @click=${(_arg_5) => {
        dispatch(new Msg(0, []));
    }}>Fill from Excel</button>
                <tool-tip inert role="tooltip" tip-position="bottom">
                  Download the template and fill the excel file then upload. Then all items will be filled automatically.
                </tool-tip> 
            </span>
            <dialog ${Lit_refValue_Z4DF72B44(dialogRef)}>
                <button type=button class="close deny" @click=${(_arg_6) => {
        dispatch(new Msg(1, []));
    }}>
                    <img src="/assets/icons/icons8-close.svg" alt='close button'>
                </button>
                ${formContent}
            </dialog>
        </div>
    `);
});

export const FillFromExcel = (new LitElementAttribute("bindrake-fillfromexcel")).Decorate(function () {
    Hook_useHmr_ZF11B4D8(hmr, this);
    const host = (this).init((arg) => {
        let a;
        a = (arg.useShadowDom = true);
        return Promise.resolve(undefined);
    })[0];
    cleanDSL(host);
    const userAttribute = document.querySelector("bfb-login").getAttribute("user");
    const isLoggedIn = (userAttribute === defaultOf()) ? false : (!(userAttribute === ""));
    const program = Program_mkHiddenProgramWithOrderExecute(() => init(isLoggedIn, undefined), update, (order, dispatch) => {
        execute(host, order, dispatch);
    });
    const patternInput_1 = LitElmishExtensions_useElmish(Hook_getContext_343DAFF1(this), () => program);
    return view(host, patternInput_1[0], patternInput_1[1]);
});

export function register() {
}

