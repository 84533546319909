import * as THREE from 'three';
import React, { useRef, useState, useMemo } from 'react';
import { Canvas, useThree, useFrame } from '@react-three/fiber';
import { EffectComposer, DepthOfField, ToneMapping } from '@react-three/postprocessing';

interface BoxProps {
  index: number;
  z: number;
  speed: number;
}

const Box: React.FC<BoxProps> = ({ index, z, speed }) => {
  const ref = useRef<THREE.Mesh>(null);
  const { viewport, camera } = useThree();
  const { width, height } = viewport.getCurrentViewport(camera, [0, 0, -z]);

  const data = useMemo(() => ({
    y: THREE.MathUtils.randFloatSpread(height * 2),
    x: THREE.MathUtils.randFloatSpread(2),
    spin: THREE.MathUtils.randFloat(16, 48),
    rX: Math.random() * Math.PI,
    rZ: Math.random() * Math.PI,
    color: new THREE.Color(Math.random(), Math.random(), Math.random()),
    size: [THREE.MathUtils.randFloat(1.5, 2.5), THREE.MathUtils.randFloat(1.5, 2.5), THREE.MathUtils.randFloat(1.5, 2.5)] as [number, number, number]
  }), [height]);

  let frame = 0;
  useFrame((state, dt) => {
    const adjustedSpeed = speed * 0.2;
    frame += 1;

  

    if (ref.current) {
      ref.current.position.set(index === 0 ? 0 : data.x * width, (data.y += dt * adjustedSpeed), -z);
      ref.current.rotation.set(
        (data.rX += dt / data.spin),
        Math.sin(index * 1000 + state.clock.elapsedTime / 20000) * Math.PI,
        (data.rZ += dt / data.spin)
      );
      if (data.y > height * (index === 0 ? 4 : 1)) data.y = -(height * (index === 0 ? 4 : 1));
    }
  });

  return (
    <mesh ref={ref} position={[data.x * width, data.y, -z]}>
      <boxGeometry args={data.size} />
      <meshStandardMaterial color={data.color} />
    </mesh>
  );
}

interface BoxesProps {
  speed?: number;
  count?: number;
  depth?: number;
  easing?: (x: number) => number;
}

const defaultEasing = (x: number) => Math.sqrt(1 - Math.pow(x - 1, 2));

export const Boxes: React.FC<BoxesProps> = ({
  speed = 1,
  count = 20,  // Reduced the number of boxes to improve performance
  depth = 80,
  easing = defaultEasing
}) => {
  return (
    <Canvas flat gl={{ antialias: false }} dpr={[1, 1.5]} camera={{ position: [0, 0, 10], fov: 20, near: 0.01, far: depth + 15 }}>
      <spotLight position={[10, 20, 10]} penumbra={1} decay={0} intensity={16} color="white" />
      {Array.from({ length: count }, (_, i) => (
        <Box key={i} index={i} z={Math.round(easing(i / count) * depth)} speed={speed} />
      ))}
      <EffectComposer multisampling={0}>
        <DepthOfField target={[0, 0, 60]} focalLength={0.1} bokehScale={4} height={700} />
        <ToneMapping />
      </EffectComposer>
    </Canvas>
  );
}