import { React_toLit_Z37A887C4 } from "./Lit.React.js";
import { createElement } from "react";
import { Boxes } from "../About.tsx";
import { HookComponentAttribute } from "./fable_modules/Fable.Lit.1.4.2/Hook.fs.js";
import { LitHelpers_html } from "./fable_modules/Fable.Lit.1.4.2/Lit.fs.js";
import { fmt } from "./fable_modules/fable-library-js.4.19.3/String.js";
import { LitElementAttribute } from "./fable_modules/Fable.Lit.1.4.2/LitElement.fs.js";
import { Hook_useHmr_ZF11B4D8 } from "./fable_modules/Fable.Lit.1.4.2/Hook.fs.js";

const hmr = undefined;

export const BackgroundRTFComp = React_toLit_Z37A887C4((tupledArg) => createElement(Boxes, {
    speed: tupledArg[0],
    count: tupledArg[1],
}));

export const view = (new HookComponentAttribute()).Decorate(function () {
    return LitHelpers_html(fmt`${BackgroundRTFComp([3, 70])}`);
});

export const Background = (new LitElementAttribute("bindrake-background")).Decorate(function () {
    Hook_useHmr_ZF11B4D8(hmr, this);
    const patternInput = (this).init((arg) => {
        let a;
        a = (arg.useShadowDom = false);
        return Promise.resolve(undefined);
    });
    return view();
});

export function register() {
}

