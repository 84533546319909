import { Union, Record } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { Version_$reflection, Authentication_Email_$reflection, CreditSystem_CreditData_$reflection } from "../Domain/Model.js";
import { union_type, class_type, record_type, option_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";

export class Model extends Record {
    constructor(Credit) {
        super();
        this.Credit = Credit;
    }
}

export function Model_$reflection() {
    return record_type("BestFitBox.Client.Store.Credit.Model", [], Model, () => [["Credit", option_type(CreditSystem_CreditData_$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["CreditRetrieved", "CreditRequested", "CreditFetchFailed"];
    }
}

export function Msg_$reflection() {
    return union_type("BestFitBox.Client.Store.Credit.Msg", [], Msg, () => [[["Item", CreditSystem_CreditData_$reflection()]], [["Item1", Authentication_Email_$reflection()], ["Item2", Version_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

export class Order extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["None", "GetCredit"];
    }
}

export function Order_$reflection() {
    return union_type("BestFitBox.Client.Store.Credit.Order", [], Order, () => [[], [["Item1", Authentication_Email_$reflection()], ["Item2", Version_$reflection()]]]);
}

export function init() {
    return [new Model(undefined), new Order(0, [])];
}

export function update(msg, model) {
    switch (msg.tag) {
        case 1:
            return [model, new Order(1, [msg.fields[0], msg.fields[1]])];
        case 2:
            return [model, new Order(0, [])];
        default:
            return [new Model(msg.fields[0]), new Order(0, [])];
    }
}

export function dispose(_arg) {
}

