import { ignore, startImmediate } from "./fable_modules/fable-library-js.4.19.3/Async.js";
import { singleton } from "./fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { FSharp_Control_IAsyncObservable$1__IAsyncObservable$1_SubscribeAsync_307B9986, System_IObservable$1__IObservable$1_ToAsyncObservable, AsyncRx_filter, AsyncRx_take } from "./fable_modules/FSharp.Control.AsyncRx.1.6.7/AsyncObservable.fs.js";
import { defaultOf, compare } from "./fable_modules/fable-library-js.4.19.3/Util.js";
import { value as value_1 } from "./fable_modules/fable-library-js.4.19.3/Option.js";
import { dispatcher, store } from "./Store.js";
import { update, init, Msg } from "./MVU/UserAndCredit.js";
import { Msg as Msg_1 } from "./MVU/Store.js";
import { Hook_createDisposable_3A5B6456, HookComponentAttribute } from "./fable_modules/Fable.Lit.1.4.2/Hook.fs.js";
import { Hook_getContext_343DAFF1, Hook_useHmr_ZF11B4D8 } from "./fable_modules/Fable.Lit.1.4.2/Hook.fs.js";
import { LitHelpers_html, Lit_get_nothing } from "./fable_modules/Fable.Lit.1.4.2/Lit.fs.js";
import { join, fmt } from "./fable_modules/fable-library-js.4.19.3/String.js";
import { Authentication_Email_TryCreate_Z721C83C5, Authentication_Email__get_Value, CreditSystem_Credit__get_Value } from "./Domain/Model.js";
import { Prop_Of_Z4A92C983, LitElementAttribute } from "./fable_modules/Fable.Lit.1.4.2/LitElement.fs.js";
import { cleanDSL } from "./ViewFunctions.js";
import { map } from "./fable_modules/fable-library-js.4.19.3/List.js";
import { toString } from "./fable_modules/fable-library-js.4.19.3/Types.js";
import { parse } from "./fable_modules/fable-library-js.4.19.3/Boolean.js";
import { Program_mkHiddenProgramWithOrderExecute } from "./ElmishOrder.js";
import { LitElmishExtensions_useElmish } from "./fable_modules/Fable.Lit.Elmish.1.4.0/Lit.Elmish.fs.js";

const hmr = undefined;

export function execute(_host, order, dispatch) {
    if (order.tag === 1) {
        const version = order.fields[1];
        startImmediate(singleton.Delay(() => {
            const asyncStore = AsyncRx_take(1, AsyncRx_filter((x) => {
                if (x.Credit != null) {
                    return compare(value_1(x.Credit).Version, version) >= 0;
                }
                else {
                    return false;
                }
            }, System_IObservable$1__IObservable$1_ToAsyncObservable(store)));
            return singleton.Bind(ignore(FSharp_Control_IAsyncObservable$1__IAsyncObservable$1_SubscribeAsync_307B9986(asyncStore, (model) => {
                let matchResult, credit;
                if (model.tag === 0) {
                    if (model.fields[0].Credit != null) {
                        matchResult = 0;
                        credit = model.fields[0].Credit;
                    }
                    else {
                        matchResult = 1;
                    }
                }
                else {
                    matchResult = 1;
                }
                switch (matchResult) {
                    case 0: {
                        dispatch(new Msg(0, [credit.Credit]));
                        break;
                    }
                }
                return singleton.Delay(() => singleton.Return(undefined));
            })), () => {
                dispatcher(new Msg_1(1, [order.fields[0], version]));
                return singleton.Zero();
            });
        }));
    }
}

export const view = (new HookComponentAttribute()).Decorate(function (host, model, dispatch) {
    Hook_useHmr_ZF11B4D8(hmr, this);
    Hook_getContext_343DAFF1(this).useEffectOnce(() => {
        const creditHandler = (e) => {
            const customEvent = e;
            dispatch(new Msg(2, [customEvent.detail]));
        };
        document.addEventListener("calculationCompleted", creditHandler);
        const loginHandler = (e_1) => {
            const customEvent_1 = e_1;
            dispatch(new Msg(3, [customEvent_1.detail]));
        };
        document.addEventListener("loggedIn", loginHandler);
        document.addEventListener("loggedOut", loginHandler);
        return Hook_createDisposable_3A5B6456(() => {
            document.removeEventListener("calculationCompleted", creditHandler);
            document.removeEventListener("loggedIn", loginHandler);
            document.removeEventListener("loggedOut", (e_2) => {
                dispatch(new Msg(4, []));
            });
        });
    });
    const matchValue = model.User;
    const matchValue_1 = model.Credit;
    let matchResult, credit, user;
    if (matchValue != null) {
        if (matchValue_1 != null) {
            matchResult = 0;
            credit = matchValue_1;
            user = matchValue;
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0: {
            const credit_1 = model.Subscriber ? Lit_get_nothing() : LitHelpers_html(fmt` <div class="credit">
                <span class="creditText">Calculations: </span>
                <span class="creditValue">${CreditSystem_Credit__get_Value(credit)}</span>
            </div>`);
            return LitHelpers_html(fmt`
            <div class="user-and-credit">
                <div class=user-name>${Authentication_Email__get_Value(user)}</div>
                ${credit_1}
            </div>
        `);
        }
        default:
            return Lit_get_nothing();
    }
});

export const UserAndCredit = (new LitElementAttribute("bfb-user-credit")).Decorate(function () {
    let e_1;
    Hook_useHmr_ZF11B4D8(hmr, this);
    const patternInput = (this).init((arg) => {
        let a;
        const config = arg;
        config.useShadowDom = true;
        a = (config.props = {
            subscriber: Prop_Of_Z4A92C983("false"),
        });
        return Promise.resolve(undefined);
    });
    const host = patternInput[0];
    cleanDSL(host);
    const userAttribute = document.querySelector("bfb-login").getAttribute("user");
    const userId = (userAttribute === defaultOf()) ? undefined : ((userAttribute === "") ? undefined : ((e_1 = Authentication_Email_TryCreate_Z721C83C5(userAttribute), (e_1.tag === 1) ? (() => {
        throw new Error(join(", ", map((x_2) => {
            let copyOfStruct = x_2;
            return toString(copyOfStruct);
        }, e_1.fields[0])));
    })() : e_1.fields[0])));
    const subscriber = parse(patternInput[1].subscriber);
    const program = Program_mkHiddenProgramWithOrderExecute(() => init(userId, subscriber, undefined), update, (order, dispatch) => {
        execute(host, order, dispatch);
    });
    const patternInput_1 = LitElmishExtensions_useElmish(Hook_getContext_343DAFF1(this), () => program);
    return view(host, patternInput_1[0], patternInput_1[1]);
});

export function register() {
}

