import { Remoting_buildProxy_64DC51C } from "./fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder } from "./fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { Calculation_$reflection, Route_builder } from "./Domain/API.js";
import { fromContinuations, startImmediate } from "./fable_modules/fable-library-js.4.19.3/Async.js";
import { singleton } from "./fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { update, init, Msg } from "./MVU/BFBInputForm.js";
import { value as value_10, some } from "./fable_modules/fable-library-js.4.19.3/Option.js";
import { LitElementAttribute, Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4 } from "./fable_modules/Fable.Lit.1.4.2/LitElement.fs.js";
import { ProxyRequestException__get_ResponseText, ProxyRequestException } from "./fable_modules/Fable.Remoting.Client.7.32.0/Types.fs.js";
import { stringHash, curry7, comparePrimitives, int32ToString, defaultOf } from "./fable_modules/fable-library-js.4.19.3/Util.js";
import { FSharpResult$2 } from "./fable_modules/fable-library-js.4.19.3/Result.js";
import { Hook_createDisposable_3A5B6456, HookComponentAttribute } from "./fable_modules/Fable.Lit.1.4.2/Hook.fs.js";
import { Hook_get_emptyDisposable, Hook_getContext_343DAFF1, Hook_useHmr_ZF11B4D8 } from "./fable_modules/Fable.Lit.1.4.2/Hook.fs.js";
import { cleanDSL, changeUrlQueryParams, deleteUrlQueryParams, heading as heading_1, baseLevel as baseLevel_1 } from "./ViewFunctions.js";
import { endsWith, join, fmt, printf, toText, substring } from "./fable_modules/fable-library-js.4.19.3/String.js";
import { Calculation_BFBInput, Calculation_Quantity_TryCreate_Z524259A4, Calculation_LWH, Calculation_Weight_TryCreate_Z5095E5A6, Calculation_Dim_TryCreate_Z5095E5A6, Calculation_ItemPut, Calculation_Item, Calculation_Container, Calculation_Weight_TryCreate_Z524259A4, Calculation_Dim__get_Value, Calculation_LWH_Create_Z721C83C5, Calculation_Multiplier } from "./Domain/Model.js";
import { ofSeq, empty, FSharpMap__TryFind } from "./fable_modules/fable-library-js.4.19.3/Map.js";
import { Lit_get_nothing, Lit_mapiUnique, LitHelpers_html } from "./fable_modules/Fable.Lit.1.4.2/Lit.fs.js";
import { filter as filter_1, length, ofArray, mapIndexed, map } from "./fable_modules/fable-library-js.4.19.3/List.js";
import { toString } from "./fable_modules/fable-library-js.4.19.3/Types.js";
import { op_Multiply, op_Division, toString as toString_1 } from "./fable_modules/fable-library-js.4.19.3/Decimal.js";
import Decimal from "./fable_modules/fable-library-js.4.19.3/Decimal.js";
import { CanvasOutput } from "./MVU/Canvas.js";
import { exists, map as map_1, toList, filter } from "./fable_modules/fable-library-js.4.19.3/Seq.js";
import { fromInt32, toInt64 } from "./fable_modules/fable-library-js.4.19.3/BigInt.js";
import { sequenceValidationA } from "./fable_modules/FsToolkit.ErrorHandling.4.15.2/List.fs.js";
import { groupBy } from "./fable_modules/fable-library-js.4.19.3/Seq2.js";
import { Program_mkHiddenProgramWithOrderExecute } from "./ElmishOrder.js";
import { LitElmishExtensions_useElmish } from "./fable_modules/Fable.Lit.Elmish.1.4.0/Lit.Elmish.fs.js";

const hmr = undefined;

export const Server_api = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Route_builder, RemotingModule_createApi()), Calculation_$reflection());

export function execute(host, order, dispatch) {
    switch (order.tag) {
        case 2: {
            startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Server_api.BFBCalculate(order.fields[0]), (_arg) => {
                const result = _arg;
                dispatch(new Msg(3, [result]));
                if (result.tag === 1) {
                    console.error(some(result.fields[0]));
                    return singleton.Zero();
                }
                else {
                    Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4(host, "calculationCompleted", result.fields[0].CreditVersion, true, true);
                    return singleton.Zero();
                }
            })), (_arg_1) => {
                if (_arg_1 instanceof ProxyRequestException) {
                    const ex = _arg_1;
                    dispatch(new Msg(4, [ProxyRequestException__get_ResponseText(ex)]));
                    return singleton.Zero();
                }
                else {
                    throw _arg_1;
                    return defaultOf();
                }
            })));
            break;
        }
        case 3: {
            window.showError(order.fields[0]);
            break;
        }
        case 4: {
            startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Server_api.BFBLoadData(order.fields[0], "bfb"), (_arg_2) => {
                dispatch(new Msg(3, [new FSharpResult$2(0, [_arg_2])]));
                return singleton.Zero();
            })), (_arg_3) => {
                dispatch(new Msg(4, ["Cannot load data."]));
                return singleton.Zero();
            })));
            break;
        }
        case 0: {
            break;
        }
        default:
            Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4(host, "loginRequested", undefined, true, true);
    }
}

export const view = (new HookComponentAttribute()).Decorate(function (host, model, dispatch) {
    let matchValue_1;
    Hook_useHmr_ZF11B4D8(hmr, this);
    let heading;
    const baseLevel = baseLevel_1(host) | 0;
    heading = ((level) => ((attributes) => ((children) => heading_1(baseLevel, level, attributes, children))));
    Hook_getContext_343DAFF1(this).useEffectOnce(() => {
        try {
            const window$ = window.top;
            const matchValue = window$.location.search;
            if (matchValue.indexOf("?g=") === 0) {
                dispatch(new Msg(5, [substring(window.location.search, 3)]));
            }
        }
        catch (e) {
            console.error(some(e));
        }
        return Hook_get_emptyDisposable();
    });
    Hook_getContext_343DAFF1(this).useEffectOnce(() => {
        const loggedIn = (_arg) => {
            dispatch(new Msg(6, []));
        };
        const loggedOut = (_arg_1) => {
            const value = deleteUrlQueryParams("g", true);
            dispatch(new Msg(7, []));
        };
        document.addEventListener("loggedIn", loggedIn);
        document.addEventListener("loggedOut", loggedOut);
        return Hook_createDisposable_3A5B6456(() => {
            document.removeEventListener("viewIn3d", loggedIn);
            document.removeEventListener("loggedOut", loggedOut);
        });
    });
    const isCalculating = ((matchValue_1 = model.CalculationStatus, (matchValue_1.tag === 1) ? [true, matchValue_1.fields[0]] : [false, new Calculation_Multiplier(1n)]))[0];
    const dimCell = (formData, prefix, minValue, maxValue, defaultValue, name, index) => {
        let s_2, firstChar, rest, capitalizedFirstChar;
        const formName = (index === "") ? toText(printf("%s-%s"))(prefix)(name) : toText(printf("%s-%s-%s"))(prefix)(index)(name);
        let defaultValue_1;
        const matchValue_2 = FSharpMap__TryFind(formData, formName);
        defaultValue_1 = ((matchValue_2 != null) ? matchValue_2 : ((defaultValue != null) ? int32ToString(defaultValue) : ""));
        return LitHelpers_html(fmt`
            <td><input name='${formName}' aria-label="${name}"
                placeholder='${(s_2 = name, (s_2 === defaultOf()) ? s_2 : ((s_2 === "") ? s_2 : ((firstChar = s_2[0], (rest = s_2.slice(1, s_2.length), (capitalizedFirstChar = firstChar.toLocaleUpperCase(), toText(printf("%c%s"))(capitalizedFirstChar)(rest)))))))}' step='${"0.01"}' required type=number min=${minValue} .value=${defaultValue_1} max=${maxValue}></td>
        `);
    };
    let formData_1;
    const matchValue_3 = model.CurrentForm;
    formData_1 = ((matchValue_3 != null) ? matchValue_3 : empty({
        Compare: comparePrimitives,
    }));
    const itemDimCell = curry7(dimCell)(formData_1)("item");
    const containerDimCell = curry7(dimCell)(formData_1)("container");
    const containerRows = Lit_mapiUnique((row) => int32ToString(row.Index, 10), (rowIndex, item) => {
        const removeButton = item.ShowRemove ? LitHelpers_html(fmt`<button type="button" aria-label="Remove current row" @click='${(e_1) => {
            dispatch(new Msg(1, [item.Index]));
        }}'>-</button>`) : LitHelpers_html(fmt`<button type="button" aria-label="Remove current row"  aria-disabled="true"  disabled>-</button>`);
        const addButton = item.ShowAdd ? LitHelpers_html(fmt`<button type="button" aria-label="Add a new row" @click='${(e_2) => {
            dispatch(new Msg(0, []));
        }}'>+</button>`) : Lit_get_nothing();
        const itemIndex = int32ToString(item.Index);
        return LitHelpers_html(fmt`
            <tr>
                ${containerDimCell("0.01")("20000")(undefined)("length")(itemIndex)}
                ${containerDimCell("0.01")("20000")(undefined)("width")(itemIndex)}
                ${containerDimCell("0.01")("20000")(undefined)("height")(itemIndex)}
                ${containerDimCell("0")("200000")(0)("maxWeight")(itemIndex)}
                <td class="action">
                  ${removeButton}
                </td>
                <td class="action">
                    ${addButton}
                </td>
            </tr>
        `);
    }, model.Rows);
    const calculateButton = LitHelpers_html(fmt`<button ?disabled=${isCalculating} type="submit">${isCalculating ? "Calculating" : "Calculate"}</button>`);
    let resultRows;
    const matchValue_4 = model.Result;
    if (matchValue_4 == null) {
        resultRows = Lit_get_nothing();
    }
    else {
        const copyOfStruct_1 = matchValue_4[0];
        if (copyOfStruct_1.tag === 1) {
            resultRows = LitHelpers_html(fmt`
                <div aria-live=assert> Errors occurred: ${join(", ", map(toString, copyOfStruct_1.fields[0]))}</div>
            `);
        }
        else {
            const multiplier_3 = matchValue_4[1].fields[0];
            if (copyOfStruct_1.fields[0].ResultKey != null) {
                changeUrlQueryParams("g", value_10(copyOfStruct_1.fields[0].ResultKey), true);
            }
            resultRows = mapIndexed((containerIndex, outputRow_1) => {
                const multiplier_4 = new Decimal(multiplier_3);
                const lwh_1 = Calculation_LWH_Create_Z721C83C5(outputRow_1.Container);
                return LitHelpers_html(fmt`
                            <tr>
                                <td class="sr-only"> Container index: 1 </td>
                                <td>${join(" x ", map(toString_1, ofArray([op_Division(new Decimal(Calculation_Dim__get_Value(lwh_1.Length)), multiplier_4), op_Division(new Decimal(Calculation_Dim__get_Value(lwh_1.Width)), multiplier_4), op_Division(new Decimal(Calculation_Dim__get_Value(lwh_1.Height)), multiplier_4)])))}</td>
                                <td>${length(outputRow_1.ItemsPut)}</td>
                                <td>${op_Division(new Decimal(outputRow_1.RemainingVolume), op_Multiply(op_Multiply(multiplier_4, multiplier_4), multiplier_4))}</td>
                                <td> 
                                    <button type="button" @click=${(_arg_22) => {
                    let Container, e_19;
                    Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4(host, "viewIn3d", (Container = (new Calculation_Container(lwh_1, (e_19 = Calculation_Weight_TryCreate_Z524259A4(0), (e_19.tag === 1) ? (() => {
                        throw new Error(join(", ", map((x_2_2) => {
                            let copyOfStruct_2 = x_2_2;
                            return toString(copyOfStruct_2);
                        }, e_19.fields[0])));
                    })() : e_19.fields[0]))), new CanvasOutput(map((item_2) => {
                        let bind$0040;
                        return new Calculation_ItemPut((bind$0040 = item_2.Item, new Calculation_Item(bind$0040.LWH, bind$0040.Weight, "green", bind$0040.Quantity, bind$0040.Priority, bind$0040.KeepTop, bind$0040.Stack, bind$0040.KeepBottom)), item_2.Coordinates, item_2.ContainerIndex);
                    }, outputRow_1.ItemsPut), Container)), true, true);
                }}> View in 3D </button> 
                                </td>
                            </tr>
                        `);
            }, copyOfStruct_1.fields[0].OutputRows);
        }
    }
    return LitHelpers_html(fmt`
        <form aria-label="Calculation form" id="calc-form"  @submit='${(e_3) => {
        e_3.preventDefault();
        if (!(!model.IsInBulkMode ? true : window.confirm("This will consume bulk credits! Are you sure you want to calculate?"))) {
        }
        else {
            startImmediate(singleton.Delay(() => {
                const form_1 = e_3.currentTarget;
                const formData_2 = new FormData(form_1);
                const kvp = ofSeq(filter((tupledArg_1) => (tupledArg_1[0] !== "excel"), toList(map_1((tupledArg) => [tupledArg[0], toString(tupledArg[1])], formData_2.entries()))), {
                    Compare: comparePrimitives,
                });
                const weightMultiplier = (exists((tupledArg_3) => {
                    const v_3 = tupledArg_3[1];
                    if (toString(v_3).indexOf(",") >= 0) {
                        return true;
                    }
                    else {
                        return toString(v_3).indexOf(".") >= 0;
                    }
                }, filter((tupledArg_2) => endsWith(tupledArg_2[0].toLowerCase(), "weight"), formData_2.entries())) ? 100 : 1) | 0;
                const multiplier_1 = (exists((tupledArg_5) => {
                    const v_4 = tupledArg_5[1];
                    if (toString(v_4).indexOf(",") >= 0) {
                        return true;
                    }
                    else {
                        return toString(v_4).indexOf(".") >= 0;
                    }
                }, filter((tupledArg_4) => !endsWith(tupledArg_4[0].toLowerCase(), "weight"), formData_2.entries())) ? 100 : 1) | 0;
                const dimTryCreate = (name_1) => Calculation_Dim_TryCreate_Z5095E5A6(formData_2.get(name_1), toInt64(fromInt32(endsWith(name_1.toLowerCase(), "weight") ? 1 : multiplier_1)));
                let files;
                const x_2 = form_1["excel"];
                files = x_2.files;
                return singleton.Bind(fromContinuations((tupledArg_6) => {
                    const cont = tupledArg_6[0];
                    if (files.length > 0) {
                        const file = files[0];
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = ((_arg_9) => {
                            cont(reader.result);
                        });
                    }
                    else {
                        cont(undefined);
                    }
                }), (_arg_10) => {
                    let e_9, nonGracefulErrors, message;
                    let input_21;
                    const input_20 = dimTryCreate("item-length");
                    if (input_20.tag === 1) {
                        input_21 = (new FSharpResult$2(1, [input_20.fields[0]]));
                    }
                    else {
                        const input_18 = dimTryCreate("item-width");
                        if (input_18.tag === 1) {
                            input_21 = (new FSharpResult$2(1, [input_18.fields[0]]));
                        }
                        else {
                            const input_16 = dimTryCreate("item-height");
                            if (input_16.tag === 1) {
                                input_21 = (new FSharpResult$2(1, [input_16.fields[0]]));
                            }
                            else {
                                const input_14 = Calculation_Weight_TryCreate_Z5095E5A6(formData_2.get("item-weight"), toInt64(fromInt32(weightMultiplier)));
                                if (input_14.tag === 1) {
                                    input_21 = (new FSharpResult$2(1, [input_14.fields[0]]));
                                }
                                else {
                                    const input_12 = sequenceValidationA(toList(map_1((tupledArg_9) => {
                                        const k_5 = tupledArg_9[0];
                                        const input_8 = dimTryCreate(k_5 + "-length");
                                        if (input_8.tag === 1) {
                                            return new FSharpResult$2(1, [input_8.fields[0]]);
                                        }
                                        else {
                                            const input_6 = dimTryCreate(k_5 + "-width");
                                            if (input_6.tag === 1) {
                                                return new FSharpResult$2(1, [input_6.fields[0]]);
                                            }
                                            else {
                                                const input_4 = dimTryCreate(k_5 + "-height");
                                                if (input_4.tag === 1) {
                                                    return new FSharpResult$2(1, [input_4.fields[0]]);
                                                }
                                                else {
                                                    const input_2 = Calculation_Weight_TryCreate_Z5095E5A6(formData_2.get(k_5 + "-maxWeight"), toInt64(fromInt32(weightMultiplier)));
                                                    if (input_2.tag === 1) {
                                                        return new FSharpResult$2(1, [input_2.fields[0]]);
                                                    }
                                                    else {
                                                        return new FSharpResult$2(0, [new Calculation_Container(new Calculation_LWH(input_8.fields[0], input_6.fields[0], input_4.fields[0]), input_2.fields[0])]);
                                                    }
                                                }
                                            }
                                        }
                                    }, groupBy((tupledArg_8) => {
                                        const k_4 = tupledArg_8[0];
                                        return substring(k_4, 0, k_4.lastIndexOf("-"));
                                    }, filter((tupledArg_7) => (tupledArg_7[0].indexOf("container-") === 0), formData_2.entries()), {
                                        Equals: (x_3, y_2) => (x_3 === y_2),
                                        GetHashCode: stringHash,
                                    }))));
                                    input_21 = ((input_12.tag === 1) ? (new FSharpResult$2(1, [input_12.fields[0]])) : (new FSharpResult$2(0, [new Calculation_BFBInput(kvp, model.Rows, model.NextRowIndex, input_12.fields[0], new Calculation_Item(new Calculation_LWH(input_20.fields[0], input_18.fields[0], input_16.fields[0]), input_14.fields[0], "", (e_9 = Calculation_Quantity_TryCreate_Z524259A4(1), (e_9.tag === 1) ? (() => {
                                        throw new Error(join(", ", map((x_2_1) => {
                                            let copyOfStruct = x_2_1;
                                            return toString(copyOfStruct);
                                        }, e_9.fields[0])));
                                    })() : e_9.fields[0]), 1, false, true, false), new Calculation_Multiplier(toInt64(fromInt32(multiplier_1))), _arg_10, undefined, undefined)])));
                                }
                            }
                        }
                    }
                    return singleton.Combine((input_21.tag === 1) ? ((nonGracefulErrors = filter_1((e_16) => {
                        if (e_16.tag === 1) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }, input_21.fields[0]), (length(nonGracefulErrors) > 0) ? ((message = join(", ", map(toString, nonGracefulErrors)), (navigator.sendBeacon("/log-error", message), (window.showError(message), singleton.Zero())))) : singleton.Zero())) : ((Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4(host, "Calculating", undefined, true, true), (dispatch(new Msg(2, [input_21.fields[0], new Calculation_Multiplier(toInt64(fromInt32(multiplier_1))), kvp])), singleton.Zero()))), singleton.Delay(() => singleton.Return(undefined)));
                });
            }));
        }
    }}'>
            <fieldset class="item">
                <legend>Item dimensions</legend>
                    ${model.IsInBulkMode ? LitHelpers_html(fmt`
                    <table hidden aria-label="Item dimensions">
                    <thead>
                        <tr>
                            <th>Length</th>
                            <th>Width</th>
                            <th>Height</th>
                            <th>Weight</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            ${itemDimCell("0.01")("20000")(1)("length")("")}
                            ${itemDimCell("0.01")("20000")(1)("width")("")}
                            ${itemDimCell("0.01")("20000")(1)("height")("")}
                            ${itemDimCell("0")("200000")(0)("weight")("")}
                        </tr>
                    </tbody>
                </table>
            `) : LitHelpers_html(fmt`
                    <table aria-label="Item dimensions">
                    <thead>
                        <tr>
                            <th>Length</th>
                            <th>Width</th>
                            <th>Height</th>
                            <th>Weight</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            ${itemDimCell("1")("20000")(undefined)("length")("")}
                            ${itemDimCell("1")("20000")(undefined)("width")("")}
                            ${itemDimCell("1")("20000")(undefined)("height")("")}
                            ${itemDimCell("0")("200000")(0)("weight")("")}
                        </tr>
                    </tbody>
                </table>
            `)}
                    <input type=file name=excel id=excel-input  @change=${(e_21) => {
        if (e_21.target.files.length > 0) {
            dispatch(new Msg(8, []));
        }
        else {
            dispatch(new Msg(9, []));
        }
    }}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
            </fieldset>
            <fieldset>
                <legend>Container dimensions</legend>
                <table aria-live="polite" aria-relevant="additions removals" summary="Container dimensions">
                    <colgroup>
                        <col />
                        <col />
                        <col />
                        <col />
                        <col class="action"/>
                        <col class="action" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Length</th>
                            <th>Width</th>
                            <th>Height</th>
                            <th>Max Weight</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${containerRows}
                    </tbody>
                </table>
            </fieldset>
            ${calculateButton}
            <fieldset>
                <legend>Results</legend>
                <output>
                    <p id="results-desc">${isCalculating ? "Calculating results..." : ((model.Result == null) ? "No results calculated yet" : "Results available in the following table")}</p>
                    <table class="results" summary="Result's table">
                        <thead>
                            <tr>
                                <th class="sr-only">Container index</th>
                                <th>Dimensions</th>
                                <th>Quantity</th>
                                <th>Remaining Volume</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${resultRows}
                        </tbody>
                    </table>
                </output>
            </fieldset>
        </form>

    `);
});

export const InputForm = (new LitElementAttribute("bfb-input-form")).Decorate(function () {
    Hook_useHmr_ZF11B4D8(hmr, this);
    const host = (this).init((arg) => {
        let a;
        a = (arg.useShadowDom = true);
        return Promise.resolve(undefined);
    })[0];
    cleanDSL(host);
    const userAttribute = document.querySelector("bfb-login").getAttribute("user");
    const isLoggedIn = (userAttribute === defaultOf()) ? false : (!(userAttribute === ""));
    const program = Program_mkHiddenProgramWithOrderExecute(() => init(isLoggedIn, undefined), update, (order, dispatch) => {
        execute(host, order, dispatch);
    });
    const patternInput_1 = LitElmishExtensions_useElmish(Hook_getContext_343DAFF1(this), () => program);
    return view(host, patternInput_1[0], patternInput_1[1]);
});

export function register() {
}

