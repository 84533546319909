import { Union, Record } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, record_type, class_type, bool_type, option_type, string_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";

export class Model extends Record {
    constructor(File, Show, Data, IsLoggedIn, Busy) {
        super();
        this.File = File;
        this.Show = Show;
        this.Data = Data;
        this.IsLoggedIn = IsLoggedIn;
        this.Busy = Busy;
    }
}

export function Model_$reflection() {
    return record_type("BestFitBox.MVU.FillFromExcel.Model", [], Model, () => [["File", option_type(string_type)], ["Show", bool_type], ["Data", option_type(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]))], ["IsLoggedIn", bool_type], ["Busy", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["FillFromExcelRequested", "FillFromExcelClosed", "FileSubmitted", "FileParsed", "LoggedIn", "LoggedOut", "ErrorOccurred"];
    }
}

export function Msg_$reflection() {
    return union_type("BestFitBox.MVU.FillFromExcel.Msg", [], Msg, () => [[], [], [["Item", string_type]], [["Item", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])]], [], [], []]);
}

export class Order extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["None", "UploadFile", "RequestLogin"];
    }
}

export function Order_$reflection() {
    return union_type("BestFitBox.MVU.FillFromExcel.Order", [], Order, () => [[], [["Item", string_type]], []]);
}

export function init(isLoggedIn, unitVar) {
    return [new Model(undefined, false, undefined, isLoggedIn, false), new Order(0, [])];
}

export function update(msg, model) {
    switch (msg.tag) {
        case 0:
            if (model.IsLoggedIn) {
                return [new Model(model.File, true, model.Data, model.IsLoggedIn, model.Busy), new Order(0, [])];
            }
            else {
                return [model, new Order(2, [])];
            }
        case 2: {
            const file = msg.fields[0];
            if (model.IsLoggedIn ? true : true) {
                return [new Model(file, model.Show, model.Data, model.IsLoggedIn, true), new Order(1, [file])];
            }
            else {
                return [model, new Order(2, [])];
            }
        }
        case 3:
            return [new Model(model.File, model.Show, msg.fields[0], model.IsLoggedIn, model.Busy), new Order(0, [])];
        case 6:
            return [new Model(model.File, model.Show, model.Data, model.IsLoggedIn, false), new Order(0, [])];
        case 5:
            return [new Model(model.File, model.Show, model.Data, false, model.Busy), new Order(0, [])];
        case 4:
            return [new Model(model.File, model.Show, model.Data, true, model.Busy), new Order(0, [])];
        default:
            return [new Model(undefined, false, model.Data, model.IsLoggedIn, false), new Order(0, [])];
    }
}

